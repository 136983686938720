// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from 'api/locations'

export const list = createAsyncThunk('appStates/list', async (_, { getState }) => {
  let states = await API.listStates()
  let countries = getState().countries
  states.forEach((e) => {
    e.countryDisplayName = countries?.data?.filter((c) => c.name === e.countryName)[0]?.displayName
  })
  return states
})

export const get = createAsyncThunk('appStates/get', async name => {
  return await API.getState(name)
})

export const add = createAsyncThunk('appStates/add', async (instance, { dispatch }) => {
  const updated = await API.applyState(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(list())
  return instance
})

export const modify = createAsyncThunk('appStates/modify', async (instance, { dispatch }) => {
  const updated = await API.applyState(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appStatesSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const appStatesSlice = createSlice({
  name: 'appStates',
  initialState: {
    data: []
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appStatesSlice.reducer
