/**
 * @fileoverview gRPC-Web generated client stub for qowner.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/email_messages.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

var projects_erp_service_api_v1_model_email_message_pb = require('./email_message_pb.js')
const proto = {};
proto.qowner = {};
proto.qowner.erp = {};
proto.qowner.erp.api = {};
proto.qowner.erp.api.v1 = {};
proto.qowner.erp.api.v1.services = require('./email_messages_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.EmailMessagesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.EmailMessagesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ListEmailMessagesRequest,
 *   !proto.qowner.erp.api.v1.services.ListEmailMessagesResponse>}
 */
const methodDescriptor_EmailMessages_ListEmailMessages = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.EmailMessages/ListEmailMessages',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ListEmailMessagesRequest,
  proto.qowner.erp.api.v1.services.ListEmailMessagesResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ListEmailMessagesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.ListEmailMessagesResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ListEmailMessagesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.ListEmailMessagesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.ListEmailMessagesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.EmailMessagesClient.prototype.listEmailMessages =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.EmailMessages/ListEmailMessages',
      request,
      metadata || {},
      methodDescriptor_EmailMessages_ListEmailMessages,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ListEmailMessagesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.ListEmailMessagesResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.EmailMessagesPromiseClient.prototype.listEmailMessages =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.EmailMessages/ListEmailMessages',
      request,
      metadata || {},
      methodDescriptor_EmailMessages_ListEmailMessages);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.GetEmailMessageRequest,
 *   !proto.qowner.erp.api.v1.model.EmailMessage>}
 */
const methodDescriptor_EmailMessages_GetEmailMessage = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.EmailMessages/GetEmailMessage',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.GetEmailMessageRequest,
  projects_erp_service_api_v1_model_email_message_pb.EmailMessage,
  /**
   * @param {!proto.qowner.erp.api.v1.services.GetEmailMessageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_email_message_pb.EmailMessage.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.GetEmailMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.EmailMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.EmailMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.EmailMessagesClient.prototype.getEmailMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.EmailMessages/GetEmailMessage',
      request,
      metadata || {},
      methodDescriptor_EmailMessages_GetEmailMessage,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.GetEmailMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.EmailMessage>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.EmailMessagesPromiseClient.prototype.getEmailMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.EmailMessages/GetEmailMessage',
      request,
      metadata || {},
      methodDescriptor_EmailMessages_GetEmailMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ApplyEmailMessageRequest,
 *   !proto.qowner.erp.api.v1.model.EmailMessage>}
 */
const methodDescriptor_EmailMessages_ApplyEmailMessage = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.EmailMessages/ApplyEmailMessage',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ApplyEmailMessageRequest,
  projects_erp_service_api_v1_model_email_message_pb.EmailMessage,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ApplyEmailMessageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_email_message_pb.EmailMessage.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyEmailMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.EmailMessage)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.EmailMessage>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.EmailMessagesClient.prototype.applyEmailMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.EmailMessages/ApplyEmailMessage',
      request,
      metadata || {},
      methodDescriptor_EmailMessages_ApplyEmailMessage,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyEmailMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.EmailMessage>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.EmailMessagesPromiseClient.prototype.applyEmailMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.EmailMessages/ApplyEmailMessage',
      request,
      metadata || {},
      methodDescriptor_EmailMessages_ApplyEmailMessage);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.DeleteEmailMessageRequest,
 *   !proto.qowner.erp.api.v1.services.DeleteEmailMessageResponse>}
 */
const methodDescriptor_EmailMessages_DeleteEmailMessage = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.EmailMessages/DeleteEmailMessage',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.DeleteEmailMessageRequest,
  proto.qowner.erp.api.v1.services.DeleteEmailMessageResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.DeleteEmailMessageRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.DeleteEmailMessageResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.DeleteEmailMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.DeleteEmailMessageResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.DeleteEmailMessageResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.EmailMessagesClient.prototype.deleteEmailMessage =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.EmailMessages/DeleteEmailMessage',
      request,
      metadata || {},
      methodDescriptor_EmailMessages_DeleteEmailMessage,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.DeleteEmailMessageRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.DeleteEmailMessageResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.EmailMessagesPromiseClient.prototype.deleteEmailMessage =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.EmailMessages/DeleteEmailMessage',
      request,
      metadata || {},
      methodDescriptor_EmailMessages_DeleteEmailMessage);
};


module.exports = proto.qowner.erp.api.v1.services;

