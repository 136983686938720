// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import API from 'api/employees'

export const list = createAsyncThunk('appEmployees/list', async (params, { signal }) => {
  const response = await API.listEmployees(params)
  if (signal.aborted)
    return
  return {
    params,
    data: response.employeesList,
    totalRows: response.totalRows
  }
})

export const get = createAsyncThunk('appEmployees/get', async (name, { dispatch, getState }) => {
  const loaded = (getState().employees.data ?? []).filter(e => e.name === name)[0]
  if (loaded)
    await dispatch(appEmployees.actions.setSelected(loaded))
  return await API.getEmployee(name)
})

export const add = createAsyncThunk('appEmployees/add', async (instance) => {
  const updated = await API.applyEmployee(instance)
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const modify = createAsyncThunk('appEmployees/modify', async (instance, { dispatch, getState }) => {
  const updated = await API.applyEmployee(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appEmployees.actions.setSelected(instance))
  await dispatch(list(getState().employees.params))
  return instance
})

export const del = createAsyncThunk('appEmployees/delete', async (name) => {
  await API.deleteEmployee(name)
  return name
})

export const appEmployees = createSlice({
  name: 'appEmployees',
  initialState: {
    data: [],
    total: 1,
    params: {},
    images: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appEmployees.reducer
