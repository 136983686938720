/**
 * @fileoverview gRPC-Web generated client stub for qowner.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/properties.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var projects_erp_service_api_v1_model_property_pb = require('./property_pb.js')

var projects_erp_service_api_v1_model_money_pb = require('../money_pb.js')

var projects_erp_service_api_v1_model_list_of_strings_pb = require('../list_of_strings_pb.js')

var projects_erp_service_api_v1_model_unit_pb = require('../unit_pb.js')
const proto = {};
proto.qowner = {};
proto.qowner.erp = {};
proto.qowner.erp.api = {};
proto.qowner.erp.api.v1 = {};
proto.qowner.erp.api.v1.services = require('./properties_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.PropertiesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.PropertiesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ListPropertiesRequest,
 *   !proto.qowner.erp.api.v1.services.ListPropertiesResponse>}
 */
const methodDescriptor_Properties_ListProperties = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Properties/ListProperties',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ListPropertiesRequest,
  proto.qowner.erp.api.v1.services.ListPropertiesResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.ListPropertiesResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.ListPropertiesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.ListPropertiesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertiesClient.prototype.listProperties =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Properties/ListProperties',
      request,
      metadata || {},
      methodDescriptor_Properties_ListProperties,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.ListPropertiesResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertiesPromiseClient.prototype.listProperties =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Properties/ListProperties',
      request,
      metadata || {},
      methodDescriptor_Properties_ListProperties);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.GetPropertyRequest,
 *   !proto.qowner.erp.api.v1.model.Property>}
 */
const methodDescriptor_Properties_GetProperty = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Properties/GetProperty',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.GetPropertyRequest,
  projects_erp_service_api_v1_model_property_pb.Property,
  /**
   * @param {!proto.qowner.erp.api.v1.services.GetPropertyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_property_pb.Property.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.Property)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.Property>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertiesClient.prototype.getProperty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Properties/GetProperty',
      request,
      metadata || {},
      methodDescriptor_Properties_GetProperty,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.Property>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertiesPromiseClient.prototype.getProperty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Properties/GetProperty',
      request,
      metadata || {},
      methodDescriptor_Properties_GetProperty);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ApplyPropertyRequest,
 *   !proto.qowner.erp.api.v1.model.Property>}
 */
const methodDescriptor_Properties_ApplyProperty = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Properties/ApplyProperty',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ApplyPropertyRequest,
  projects_erp_service_api_v1_model_property_pb.Property,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_property_pb.Property.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.Property)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.Property>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertiesClient.prototype.applyProperty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Properties/ApplyProperty',
      request,
      metadata || {},
      methodDescriptor_Properties_ApplyProperty,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.Property>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertiesPromiseClient.prototype.applyProperty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Properties/ApplyProperty',
      request,
      metadata || {},
      methodDescriptor_Properties_ApplyProperty);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.DeletePropertyRequest,
 *   !proto.qowner.erp.api.v1.services.DeletePropertyResponse>}
 */
const methodDescriptor_Properties_DeleteProperty = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Properties/DeleteProperty',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.DeletePropertyRequest,
  proto.qowner.erp.api.v1.services.DeletePropertyResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.DeletePropertyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.DeletePropertyResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.DeletePropertyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.DeletePropertyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertiesClient.prototype.deleteProperty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Properties/DeleteProperty',
      request,
      metadata || {},
      methodDescriptor_Properties_DeleteProperty,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.DeletePropertyResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertiesPromiseClient.prototype.deleteProperty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Properties/DeleteProperty',
      request,
      metadata || {},
      methodDescriptor_Properties_DeleteProperty);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest,
 *   !proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse>}
 */
const methodDescriptor_Properties_GeneratePropertyDescription = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Properties/GeneratePropertyDescription',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest,
  proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertiesClient.prototype.generatePropertyDescription =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Properties/GeneratePropertyDescription',
      request,
      metadata || {},
      methodDescriptor_Properties_GeneratePropertyDescription,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertiesPromiseClient.prototype.generatePropertyDescription =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Properties/GeneratePropertyDescription',
      request,
      metadata || {},
      methodDescriptor_Properties_GeneratePropertyDescription);
};


module.exports = proto.qowner.erp.api.v1.services;

