/**
 * @fileoverview gRPC-Web generated client stub for qowner.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/countries.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

var projects_erp_service_api_v1_model_country_pb = require('./country_pb.js')
const proto = {};
proto.qowner = {};
proto.qowner.erp = {};
proto.qowner.erp.api = {};
proto.qowner.erp.api.v1 = {};
proto.qowner.erp.api.v1.services = require('./countries_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.CountriesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.CountriesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ListCountriesRequest,
 *   !proto.qowner.erp.api.v1.services.ListCountriesResponse>}
 */
const methodDescriptor_Countries_ListCountries = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Countries/ListCountries',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ListCountriesRequest,
  proto.qowner.erp.api.v1.services.ListCountriesResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ListCountriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.ListCountriesResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ListCountriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.ListCountriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.ListCountriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.CountriesClient.prototype.listCountries =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Countries/ListCountries',
      request,
      metadata || {},
      methodDescriptor_Countries_ListCountries,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ListCountriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.ListCountriesResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.CountriesPromiseClient.prototype.listCountries =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Countries/ListCountries',
      request,
      metadata || {},
      methodDescriptor_Countries_ListCountries);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.GetCountryRequest,
 *   !proto.qowner.erp.api.v1.model.Country>}
 */
const methodDescriptor_Countries_GetCountry = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Countries/GetCountry',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.GetCountryRequest,
  projects_erp_service_api_v1_model_country_pb.Country,
  /**
   * @param {!proto.qowner.erp.api.v1.services.GetCountryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_country_pb.Country.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.GetCountryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.Country)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.Country>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.CountriesClient.prototype.getCountry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Countries/GetCountry',
      request,
      metadata || {},
      methodDescriptor_Countries_GetCountry,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.GetCountryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.Country>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.CountriesPromiseClient.prototype.getCountry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Countries/GetCountry',
      request,
      metadata || {},
      methodDescriptor_Countries_GetCountry);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ApplyCountryRequest,
 *   !proto.qowner.erp.api.v1.model.Country>}
 */
const methodDescriptor_Countries_ApplyCountry = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Countries/ApplyCountry',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ApplyCountryRequest,
  projects_erp_service_api_v1_model_country_pb.Country,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ApplyCountryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_country_pb.Country.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyCountryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.Country)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.Country>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.CountriesClient.prototype.applyCountry =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Countries/ApplyCountry',
      request,
      metadata || {},
      methodDescriptor_Countries_ApplyCountry,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyCountryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.Country>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.CountriesPromiseClient.prototype.applyCountry =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Countries/ApplyCountry',
      request,
      metadata || {},
      methodDescriptor_Countries_ApplyCountry);
};


module.exports = proto.qowner.erp.api.v1.services;

