// source: projects/erp/service/api/v1/services/property_confirmations.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var projects_erp_service_api_v1_model_property_confirmation_pb = require('./property_confirmation_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_property_confirmation_pb);
goog.exportSymbol('proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest.displayName = 'proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.repeatedFields_, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.displayName = 'proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest.displayName = 'proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest.displayName = 'proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest.displayName = 'proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse.displayName = 'proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyName: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest}
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest;
  return proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest}
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPropertyName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string property_name = 1;
 * @return {string}
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest.prototype.getPropertyName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest.prototype.setPropertyName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyConfirmationsList: jspb.Message.toObjectList(msg.getPropertyConfirmationsList(),
    projects_erp_service_api_v1_model_property_confirmation_pb.PropertyConfirmation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse}
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse;
  return proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse}
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_property_confirmation_pb.PropertyConfirmation;
      reader.readMessage(value,projects_erp_service_api_v1_model_property_confirmation_pb.PropertyConfirmation.deserializeBinaryFromReader);
      msg.addPropertyConfirmations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyConfirmationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      projects_erp_service_api_v1_model_property_confirmation_pb.PropertyConfirmation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated qowner.erp.api.v1.model.PropertyConfirmation property_confirmations = 1;
 * @return {!Array<!proto.qowner.erp.api.v1.model.PropertyConfirmation>}
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.prototype.getPropertyConfirmationsList = function() {
  return /** @type{!Array<!proto.qowner.erp.api.v1.model.PropertyConfirmation>} */ (
    jspb.Message.getRepeatedWrapperField(this, projects_erp_service_api_v1_model_property_confirmation_pb.PropertyConfirmation, 1));
};


/**
 * @param {!Array<!proto.qowner.erp.api.v1.model.PropertyConfirmation>} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse} returns this
*/
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.prototype.setPropertyConfirmationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qowner.erp.api.v1.model.PropertyConfirmation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qowner.erp.api.v1.model.PropertyConfirmation}
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.prototype.addPropertyConfirmations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qowner.erp.api.v1.model.PropertyConfirmation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.prototype.clearPropertyConfirmationsList = function() {
  return this.setPropertyConfirmationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest}
 */
proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest;
  return proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest}
 */
proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest} returns this
 */
proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertyConfirmation: (f = msg.getPropertyConfirmation()) && projects_erp_service_api_v1_model_property_confirmation_pb.PropertyConfirmation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest}
 */
proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest;
  return proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest}
 */
proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_property_confirmation_pb.PropertyConfirmation;
      reader.readMessage(value,projects_erp_service_api_v1_model_property_confirmation_pb.PropertyConfirmation.deserializeBinaryFromReader);
      msg.setPropertyConfirmation(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertyConfirmation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      projects_erp_service_api_v1_model_property_confirmation_pb.PropertyConfirmation.serializeBinaryToWriter
    );
  }
};


/**
 * optional qowner.erp.api.v1.model.PropertyConfirmation property_confirmation = 1;
 * @return {?proto.qowner.erp.api.v1.model.PropertyConfirmation}
 */
proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest.prototype.getPropertyConfirmation = function() {
  return /** @type{?proto.qowner.erp.api.v1.model.PropertyConfirmation} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_property_confirmation_pb.PropertyConfirmation, 1));
};


/**
 * @param {?proto.qowner.erp.api.v1.model.PropertyConfirmation|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest} returns this
*/
proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest.prototype.setPropertyConfirmation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest} returns this
 */
proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest.prototype.clearPropertyConfirmation = function() {
  return this.setPropertyConfirmation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest.prototype.hasPropertyConfirmation = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest}
 */
proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest;
  return proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest}
 */
proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest} returns this
 */
proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse}
 */
proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse;
  return proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse}
 */
proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.qowner.erp.api.v1.services);
