/**
 * @fileoverview gRPC-Web generated client stub for qowner.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/tasks.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var projects_erp_service_api_v1_model_task_pb = require('./task_pb.js')
const proto = {};
proto.qowner = {};
proto.qowner.erp = {};
proto.qowner.erp.api = {};
proto.qowner.erp.api.v1 = {};
proto.qowner.erp.api.v1.services = require('./tasks_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.TasksClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.TasksPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ListTasksRequest,
 *   !proto.qowner.erp.api.v1.services.ListTasksResponse>}
 */
const methodDescriptor_Tasks_ListTasks = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Tasks/ListTasks',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ListTasksRequest,
  proto.qowner.erp.api.v1.services.ListTasksResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ListTasksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.ListTasksResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ListTasksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.ListTasksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.ListTasksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.TasksClient.prototype.listTasks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Tasks/ListTasks',
      request,
      metadata || {},
      methodDescriptor_Tasks_ListTasks,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ListTasksRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.ListTasksResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.TasksPromiseClient.prototype.listTasks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Tasks/ListTasks',
      request,
      metadata || {},
      methodDescriptor_Tasks_ListTasks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.GetTaskRequest,
 *   !proto.qowner.erp.api.v1.model.Task>}
 */
const methodDescriptor_Tasks_GetTask = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Tasks/GetTask',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.GetTaskRequest,
  projects_erp_service_api_v1_model_task_pb.Task,
  /**
   * @param {!proto.qowner.erp.api.v1.services.GetTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_task_pb.Task.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.GetTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.Task)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.Task>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.TasksClient.prototype.getTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Tasks/GetTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_GetTask,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.GetTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.Task>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.TasksPromiseClient.prototype.getTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Tasks/GetTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_GetTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ApplyTaskRequest,
 *   !proto.qowner.erp.api.v1.model.Task>}
 */
const methodDescriptor_Tasks_ApplyTask = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Tasks/ApplyTask',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ApplyTaskRequest,
  projects_erp_service_api_v1_model_task_pb.Task,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ApplyTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_task_pb.Task.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.Task)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.Task>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.TasksClient.prototype.applyTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Tasks/ApplyTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_ApplyTask,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.Task>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.TasksPromiseClient.prototype.applyTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Tasks/ApplyTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_ApplyTask);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.DeleteTaskRequest,
 *   !proto.qowner.erp.api.v1.services.DeleteTaskResponse>}
 */
const methodDescriptor_Tasks_DeleteTask = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Tasks/DeleteTask',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.DeleteTaskRequest,
  proto.qowner.erp.api.v1.services.DeleteTaskResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.DeleteTaskRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.DeleteTaskResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.DeleteTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.DeleteTaskResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.DeleteTaskResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.TasksClient.prototype.deleteTask =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Tasks/DeleteTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteTask,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.DeleteTaskRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.DeleteTaskResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.TasksPromiseClient.prototype.deleteTask =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Tasks/DeleteTask',
      request,
      metadata || {},
      methodDescriptor_Tasks_DeleteTask);
};


module.exports = proto.qowner.erp.api.v1.services;

