// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import API from 'api/property_collections'

export const listCollections = createAsyncThunk('appCollections/listCollections', async (params, { signal }) => {
  const response = await API.listPropertyCollections(params)
  if (signal.aborted)
    return
  return {
    params,
    data: response.propertyCollectionsList,
    totalRows: response.totalRows
  }
})

export const getCollection = createAsyncThunk('appCollections/getCollection',  async name => {
  return await API.getPropertyCollection(name)
})

export const addCollection = createAsyncThunk('appCollections/addCollection', async (instance) => {
  const updated = await API.applyPropertyCollection(instance)
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const modifyCollection = createAsyncThunk('appCollections/modifyCollection', async (instance) => {
  const updated = await API.applyPropertyCollection(instance)
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const deleteCollection = createAsyncThunk('appCollections/deleteCollection', async (name) => {
  await API.deletePropertyCollection(name)
  return name
})

export const appCollections = createSlice({
  name: 'appCollections',
  initialState: {
    data: [],
    total: 1,
    params: {},
    images: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(listCollections.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(getCollection.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
    }
})

export default appCollections.reducer
