/**
 * @fileoverview gRPC-Web generated client stub for qowner.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/property_collections.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var projects_erp_service_api_v1_model_property_collection_pb = require('./property_collection_pb.js')
const proto = {};
proto.qowner = {};
proto.qowner.erp = {};
proto.qowner.erp.api = {};
proto.qowner.erp.api.v1 = {};
proto.qowner.erp.api.v1.services = require('./property_collections_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.PropertyCollectionsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.PropertyCollectionsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ListPropertyCollectionsRequest,
 *   !proto.qowner.erp.api.v1.services.ListPropertyCollectionsResponse>}
 */
const methodDescriptor_PropertyCollections_ListPropertyCollections = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyCollections/ListPropertyCollections',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ListPropertyCollectionsRequest,
  proto.qowner.erp.api.v1.services.ListPropertyCollectionsResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ListPropertyCollectionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.ListPropertyCollectionsResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ListPropertyCollectionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.ListPropertyCollectionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.ListPropertyCollectionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyCollectionsClient.prototype.listPropertyCollections =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyCollections/ListPropertyCollections',
      request,
      metadata || {},
      methodDescriptor_PropertyCollections_ListPropertyCollections,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ListPropertyCollectionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.ListPropertyCollectionsResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyCollectionsPromiseClient.prototype.listPropertyCollections =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyCollections/ListPropertyCollections',
      request,
      metadata || {},
      methodDescriptor_PropertyCollections_ListPropertyCollections);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.GetPropertyCollectionRequest,
 *   !proto.qowner.erp.api.v1.model.PropertyCollection>}
 */
const methodDescriptor_PropertyCollections_GetPropertyCollection = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyCollections/GetPropertyCollection',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.GetPropertyCollectionRequest,
  projects_erp_service_api_v1_model_property_collection_pb.PropertyCollection,
  /**
   * @param {!proto.qowner.erp.api.v1.services.GetPropertyCollectionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_property_collection_pb.PropertyCollection.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyCollectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.PropertyCollection)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.PropertyCollection>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyCollectionsClient.prototype.getPropertyCollection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyCollections/GetPropertyCollection',
      request,
      metadata || {},
      methodDescriptor_PropertyCollections_GetPropertyCollection,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyCollectionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.PropertyCollection>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyCollectionsPromiseClient.prototype.getPropertyCollection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyCollections/GetPropertyCollection',
      request,
      metadata || {},
      methodDescriptor_PropertyCollections_GetPropertyCollection);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ApplyPropertyCollectionRequest,
 *   !proto.qowner.erp.api.v1.model.PropertyCollection>}
 */
const methodDescriptor_PropertyCollections_ApplyPropertyCollection = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyCollections/ApplyPropertyCollection',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ApplyPropertyCollectionRequest,
  projects_erp_service_api_v1_model_property_collection_pb.PropertyCollection,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyCollectionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_property_collection_pb.PropertyCollection.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyCollectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.PropertyCollection)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.PropertyCollection>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyCollectionsClient.prototype.applyPropertyCollection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyCollections/ApplyPropertyCollection',
      request,
      metadata || {},
      methodDescriptor_PropertyCollections_ApplyPropertyCollection,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyCollectionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.PropertyCollection>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyCollectionsPromiseClient.prototype.applyPropertyCollection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyCollections/ApplyPropertyCollection',
      request,
      metadata || {},
      methodDescriptor_PropertyCollections_ApplyPropertyCollection);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.DeletePropertyCollectionRequest,
 *   !proto.qowner.erp.api.v1.services.DeletePropertyCollectionResponse>}
 */
const methodDescriptor_PropertyCollections_DeletePropertyCollection = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyCollections/DeletePropertyCollection',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.DeletePropertyCollectionRequest,
  proto.qowner.erp.api.v1.services.DeletePropertyCollectionResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.DeletePropertyCollectionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.DeletePropertyCollectionResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyCollectionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.DeletePropertyCollectionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.DeletePropertyCollectionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyCollectionsClient.prototype.deletePropertyCollection =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyCollections/DeletePropertyCollection',
      request,
      metadata || {},
      methodDescriptor_PropertyCollections_DeletePropertyCollection,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyCollectionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.DeletePropertyCollectionResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyCollectionsPromiseClient.prototype.deletePropertyCollection =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyCollections/DeletePropertyCollection',
      request,
      metadata || {},
      methodDescriptor_PropertyCollections_DeletePropertyCollection);
};


module.exports = proto.qowner.erp.api.v1.services;

