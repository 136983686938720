/**
 * @fileoverview gRPC-Web generated client stub for qowner.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/settlements.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

var projects_erp_service_api_v1_model_settlement_pb = require('./settlement_pb.js')
const proto = {};
proto.qowner = {};
proto.qowner.erp = {};
proto.qowner.erp.api = {};
proto.qowner.erp.api.v1 = {};
proto.qowner.erp.api.v1.services = require('./settlements_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.SettlementsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.SettlementsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ListSettlementsRequest,
 *   !proto.qowner.erp.api.v1.services.ListSettlementsResponse>}
 */
const methodDescriptor_Settlements_ListSettlements = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Settlements/ListSettlements',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ListSettlementsRequest,
  proto.qowner.erp.api.v1.services.ListSettlementsResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ListSettlementsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.ListSettlementsResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ListSettlementsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.ListSettlementsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.ListSettlementsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.SettlementsClient.prototype.listSettlements =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Settlements/ListSettlements',
      request,
      metadata || {},
      methodDescriptor_Settlements_ListSettlements,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ListSettlementsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.ListSettlementsResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.SettlementsPromiseClient.prototype.listSettlements =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Settlements/ListSettlements',
      request,
      metadata || {},
      methodDescriptor_Settlements_ListSettlements);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.GetSettlementRequest,
 *   !proto.qowner.erp.api.v1.model.Settlement>}
 */
const methodDescriptor_Settlements_GetSettlement = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Settlements/GetSettlement',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.GetSettlementRequest,
  projects_erp_service_api_v1_model_settlement_pb.Settlement,
  /**
   * @param {!proto.qowner.erp.api.v1.services.GetSettlementRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_settlement_pb.Settlement.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.GetSettlementRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.Settlement)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.Settlement>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.SettlementsClient.prototype.getSettlement =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Settlements/GetSettlement',
      request,
      metadata || {},
      methodDescriptor_Settlements_GetSettlement,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.GetSettlementRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.Settlement>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.SettlementsPromiseClient.prototype.getSettlement =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Settlements/GetSettlement',
      request,
      metadata || {},
      methodDescriptor_Settlements_GetSettlement);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ApplySettlementRequest,
 *   !proto.qowner.erp.api.v1.model.Settlement>}
 */
const methodDescriptor_Settlements_ApplySettlement = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Settlements/ApplySettlement',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ApplySettlementRequest,
  projects_erp_service_api_v1_model_settlement_pb.Settlement,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ApplySettlementRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_settlement_pb.Settlement.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplySettlementRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.Settlement)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.Settlement>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.SettlementsClient.prototype.applySettlement =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Settlements/ApplySettlement',
      request,
      metadata || {},
      methodDescriptor_Settlements_ApplySettlement,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplySettlementRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.Settlement>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.SettlementsPromiseClient.prototype.applySettlement =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Settlements/ApplySettlement',
      request,
      metadata || {},
      methodDescriptor_Settlements_ApplySettlement);
};


module.exports = proto.qowner.erp.api.v1.services;

