import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'
import { ApiUrl, getMetadata } from '../index'
import {ListOfStrings} from '../list_of_strings_pb'
import { Money, MoneyRange } from '../money_pb'
import { Unit, UnitRange } from '../money_pb'
import { PropertyQuery } from './property_query_pb'
import {
  GetPropertyQueryRequest,
  ListPropertyQueriesRequest,
  ApplyPropertyQueryRequest,
  DeletePropertyQueryRequest
} from './property_queries_pb'
import { PropertyQueriesClient } from './property_queries_grpc_web_pb'

let client = new PropertyQueriesClient(ApiUrl, null, null);

const toPropertyQueryModel = (instance) => {
  let result = new PropertyQuery()
  if (instance.name)
    result.setName(instance.name)
  result.setCustomerName(instance.customerName)
  result.setDisplayName(instance.displayName)
  result.setCountryName(instance.countryName)
  result.setCustomerName(instance.customerName)
  result.setIsActive(instance.isActive)
  result.setCreatedAt(instance.createdAt)
  result.setPropertyName(instance?.propertyName)

  if (instance?.description) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance?.description)
    result.setDescription(wrapped)
  }

  if (instance?.developerNamesList?.length) {
    result.setDeveloperNamesList(instance?.developerNamesList)
  }
  if (instance?.typeNamesList?.length) {
    result.setTypeNamesList(instance?.typeNamesList)
  }
  if (instance?.statusNamesList) {
    result.setStatusNamesList(instance?.statusNamesList)
  }
  if (instance?.locationNamesList) {
    result.setLocationNamesList(instance?.locationNamesList)
  }
  if (instance?.amountRange) {
    const range = new MoneyRange()
    if (instance?.amountRange?.from) {
      const amt = new Money()
      amt.setValue(instance?.amountRange?.from.value)
      amt.setCurrencyName(instance?.amountRange?.from.currencyName)
      amt.setExponent(instance?.amountRange?.from.exponent)
      range.setFrom(amt)
    }
    if (instance?.amountRange?.to) {
      const amt = new Money()
      amt.setValue(instance?.amountRange?.to.value)
      amt.setCurrencyName(instance?.amountRange?.to.currencyName)
      amt.setExponent(instance?.amountRange?.to.exponent)
      range.setTo(amt)
    }
    result.setAmountRange(range)
  }
  if (instance?.valuesMap) {
    for (const i in instance.valuesMap) {
      if (instance.valuesMap[i][1] === undefined)
        continue
      const items = new ListOfStrings()
      items.setStringsList(instance.valuesMap[i][1])
      result.getValuesMap().set(instance.valuesMap[i][0], items)
    }
  }
  if (instance?.amenitiesMap) {
    for (const i in instance.amenitiesMap) {
      if (instance.amenitiesMap[i][1] === undefined)
        continue
      const items = new ListOfStrings()
      items.setStringsList(instance.amenitiesMap[i][1])
      result.getAmenitiesMap().set(instance.amenitiesMap[i][0], items)
    }
  }
  if (instance?.unitRangesMap) {
    for (const i in instance.unitRangesMap) {
      if (instance.unitRangesMap[i][1] === undefined)
        continue
      const range = new UnitRange()
      const item = instance.unitRangesMap[i][1]
      if (item?.from) {
        const unt = new Unit()
        unt.setValue(item?.from.value)
        unt.setUnitName(item?.from.unitName)
        unt.setExponent(item?.from.exponent)
        range.setFrom(unt)
      }
      if (item?.to) {
        const unt = new Unit()
        unt.setValue(item?.to.value)
        unt.setUnitName(item?.to.unitName)
        unt.setExponent(item?.to.exponent)
        range.setTo(unt)
      }
      result.getUnitRangesMap().set(instance.unitRangesMap[i][0], range)
    }
  }

  return result
}

const toPropertyQuery = (instance) => {
  const result = {}
  Object.keys(instance).forEach(key => {
    if (key.endsWith('List') || key.endsWith('Map') || key === "amount") {
      result[key] = instance[key]
    } else if (key === "project" && instance[key]) {
      result[key] = toPropertyQuery(instance[key])
    } else if (instance[key]?.value) {
      result[key] = instance[key]?.value
    } else if (key === 'location') {
      result[key] = toPropertyQuery(instance[key])
    } else {
      result[key] = instance[key]
    }
  })
  return result
}

const API = {
  listPropertyQueries: (params) => {
    const request = new ListPropertyQueriesRequest();
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort === 'asc')
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    if (params?.customerName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.customerName)
      request.setCustomerName(wrapped)
    }
    if (params?.customerName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.customerName)
      request.setCustomerName(wrapped)
    }
    if (params?.typeName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.typeName)
      request.setTypeName(wrapped)
    }
    if (params?.statusName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.statusName)
      request.setStatusName(wrapped)
    }
    if (params?.developerNamesList?.length) {
      request.setDeveloperNamesList(params?.developerNamesList)
    }
    if (params?.typeNamesList?.length) {
      request.setTypeNamesList(params?.typeNamesList)
    }
    if (params?.statusNamesList) {
      request.setStatusNamesList(params?.statusNamesList)
    }
    if (params?.locationNamesList) {
      request.setLocationNamesList(params?.locationNamesList)
    }
    if (params?.amountRange) {
      const range = new MoneyRange()
      if (params?.amountRange?.from) {
        const amt = new Money()
        amt.setValue(params?.amountRange?.from.value)
        amt.setCurrencyName(params?.amountRange?.from.currencyName)
        amt.setExponent(params?.amountRange?.from.exponent)
        range.setFrom(amt)
      }
      if (params?.amountRange?.to) {
        const amt = new Money()
        amt.setValue(params?.amountRange?.to.value)
        amt.setCurrencyName(params?.amountRange?.to.currencyName)
        amt.setExponent(params?.amountRange?.to.exponent)
        range.setTo(amt)
      }
      request.setAmountRange(range)
    }
    if (params?.valuesMap) {
      for (const i in params.valuesMap) {
        if (params.valuesMap[i][1] === undefined)
          continue
        const items = new ListOfStrings()
        items.setStringsList(params.valuesMap[i][1])
        request.getValuesMap().set(params.valuesMap[i][0], items)
      }
    }
    if (params?.amenitiesMap) {
      for (const i in params.amenitiesMap) {
        if (params.amenitiesMap[i][1] === undefined)
          continue
        const items = new ListOfStrings()
        items.setStringsList(params.amenitiesMap[i][1])
        request.getAmenitiesMap().set(params.amenitiesMap[i][0], items)
      }
    }
    if (params?.unitRangesMap) {
      for (const i in params.unitRangesMap) {
        if (params.unitRangesMap[i][1] === undefined)
          continue
        const range = new UnitRange()
        const item = params.unitRangesMap[i][1]
        if (item?.from) {
          const unt = new Unit()
          unt.setValue(item?.from.value)
          unt.setUnitName(item?.from.unitName)
          unt.setExponent(item?.from.exponent)
          range.setFrom(unt)
        }
        if (item?.to) {
          const unt = new Unit()
          unt.setValue(item?.to.value)
          unt.setUnitName(item?.to.unitName)
          unt.setExponent(item?.to.exponent)
          range.setTo(unt)
        }
        request.getUnitRangesMap().set(params.unitRangesMap[i][0], range)
      }
    }
    if (params?.hasProperty !== undefined) {
      const wrapped = new wrappers.BoolValue()
      wrapped.setValue(!!params.hasProperty)
      request.setHasProperty(wrapped)
    }
    if (params?.description) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params?.description)
      request.setDescription(wrapped)
    }
    return new Promise((resolve) => {
      client.listPropertyQueries(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          const result = response.toObject()
          resolve({
            ...result,
            queriesList: result.queriesList?.map(p => toPropertyQuery(p))
          })
        }
      });
    });
  },
  getPropertyQuery: (name) => {
    const request = new GetPropertyQueryRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.getPropertyQuery(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyPropertyQuery: (instance) => {
    const applying = toPropertyQueryModel(instance)
    const request = new ApplyPropertyQueryRequest();
    request.setPropertyQuery(applying)
    return new Promise((resolve) => {
      client.applyPropertyQuery(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deletePropertyQuery: (name) => {
    const request = new DeletePropertyQueryRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.deletePropertyQuery(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
