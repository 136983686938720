import { ApiUrl, getMetadata } from '../index'
import { PropertyType } from './property_type_pb'
import {
  GetPropertyTypeRequest,
  ListPropertyTypesRequest,
  ApplyPropertyTypeRequest,
  DeletePropertyTypeRequest
} from './property_types_pb'
import { PropertyTypesClient } from './property_types_grpc_web_pb'

let client = new PropertyTypesClient(ApiUrl, null, null);

const toPropertyTypeModel = (instance) => {
  let result = new PropertyType()
  if (instance.name)
    result.setName(instance.name)
  result.setCode(instance.code)
  result.setDisplayName(instance.displayName)
  result.setIsActive(instance.isActive)
  return result
}

const API = {
  listPropertyTypes: () => {
    const request = new ListPropertyTypesRequest();
    return new Promise((resolve) => {
      client.listPropertyTypes(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject().propertyTypesList)
        }
      });
    });
  },
  getPropertyType: (name) => {
    const request = new GetPropertyTypeRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.getPropertyType(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyPropertyType: (instance) => {
    const applying = toPropertyTypeModel(instance)
    const request = new ApplyPropertyTypeRequest();
    request.setPropertyType(applying)
    return new Promise((resolve) => {
      client.applyPropertyType(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deletePropertyType: (name) => {
    const request = new DeletePropertyTypeRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.deletePropertyType(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
