/**
 * @fileoverview gRPC-Web generated client stub for qowner.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/property_statuses.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

var projects_erp_service_api_v1_model_property_status_pb = require('./property_status_pb.js')
const proto = {};
proto.qowner = {};
proto.qowner.erp = {};
proto.qowner.erp.api = {};
proto.qowner.erp.api.v1 = {};
proto.qowner.erp.api.v1.services = require('./property_statuses_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.PropertyStatusesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.PropertyStatusesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ListPropertyStatusesRequest,
 *   !proto.qowner.erp.api.v1.services.ListPropertyStatusesResponse>}
 */
const methodDescriptor_PropertyStatuses_ListPropertyStatuses = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyStatuses/ListPropertyStatuses',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ListPropertyStatusesRequest,
  proto.qowner.erp.api.v1.services.ListPropertyStatusesResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ListPropertyStatusesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.ListPropertyStatusesResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ListPropertyStatusesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.ListPropertyStatusesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.ListPropertyStatusesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyStatusesClient.prototype.listPropertyStatuses =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyStatuses/ListPropertyStatuses',
      request,
      metadata || {},
      methodDescriptor_PropertyStatuses_ListPropertyStatuses,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ListPropertyStatusesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.ListPropertyStatusesResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyStatusesPromiseClient.prototype.listPropertyStatuses =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyStatuses/ListPropertyStatuses',
      request,
      metadata || {},
      methodDescriptor_PropertyStatuses_ListPropertyStatuses);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.GetPropertyStatusRequest,
 *   !proto.qowner.erp.api.v1.model.PropertyStatus>}
 */
const methodDescriptor_PropertyStatuses_GetPropertyStatus = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyStatuses/GetPropertyStatus',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.GetPropertyStatusRequest,
  projects_erp_service_api_v1_model_property_status_pb.PropertyStatus,
  /**
   * @param {!proto.qowner.erp.api.v1.services.GetPropertyStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_property_status_pb.PropertyStatus.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.PropertyStatus)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.PropertyStatus>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyStatusesClient.prototype.getPropertyStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyStatuses/GetPropertyStatus',
      request,
      metadata || {},
      methodDescriptor_PropertyStatuses_GetPropertyStatus,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.PropertyStatus>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyStatusesPromiseClient.prototype.getPropertyStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyStatuses/GetPropertyStatus',
      request,
      metadata || {},
      methodDescriptor_PropertyStatuses_GetPropertyStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ApplyPropertyStatusRequest,
 *   !proto.qowner.erp.api.v1.model.PropertyStatus>}
 */
const methodDescriptor_PropertyStatuses_ApplyPropertyStatus = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyStatuses/ApplyPropertyStatus',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ApplyPropertyStatusRequest,
  projects_erp_service_api_v1_model_property_status_pb.PropertyStatus,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_property_status_pb.PropertyStatus.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.PropertyStatus)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.PropertyStatus>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyStatusesClient.prototype.applyPropertyStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyStatuses/ApplyPropertyStatus',
      request,
      metadata || {},
      methodDescriptor_PropertyStatuses_ApplyPropertyStatus,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.PropertyStatus>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyStatusesPromiseClient.prototype.applyPropertyStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyStatuses/ApplyPropertyStatus',
      request,
      metadata || {},
      methodDescriptor_PropertyStatuses_ApplyPropertyStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.DeletePropertyStatusRequest,
 *   !proto.qowner.erp.api.v1.services.DeletePropertyStatusResponse>}
 */
const methodDescriptor_PropertyStatuses_DeletePropertyStatus = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyStatuses/DeletePropertyStatus',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.DeletePropertyStatusRequest,
  proto.qowner.erp.api.v1.services.DeletePropertyStatusResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.DeletePropertyStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.DeletePropertyStatusResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.DeletePropertyStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.DeletePropertyStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyStatusesClient.prototype.deletePropertyStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyStatuses/DeletePropertyStatus',
      request,
      metadata || {},
      methodDescriptor_PropertyStatuses_DeletePropertyStatus,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.DeletePropertyStatusResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyStatusesPromiseClient.prototype.deletePropertyStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyStatuses/DeletePropertyStatus',
      request,
      metadata || {},
      methodDescriptor_PropertyStatuses_DeletePropertyStatus);
};


module.exports = proto.qowner.erp.api.v1.services;

