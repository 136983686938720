import { ApiUrl, getMetadata } from '../index'
import { PropertyStatus } from './property_status_pb'
import {
  GetPropertyStatusRequest,
  ListPropertyStatusesRequest,
  ApplyPropertyStatusRequest,
  DeletePropertyStatusRequest
} from './property_statuses_pb'
import { PropertyStatusesClient } from './property_statuses_grpc_web_pb'

let client = new PropertyStatusesClient(ApiUrl, null, null);

const tpPropertyStatusModel = (instance) => {
  let result = new PropertyStatus()
  if (instance.name)
    result.setName(instance.name)
  result.setCode(instance.code)
  result.setDisplayName(instance.displayName)
  result.setColor(instance.color)
  result.setIsPublic(instance.isPublic)
  result.setIsPrivate(instance.isPrivate)
  result.setIsActive(instance.isActive)
  return result
}

const API = {
  listPropertyStatuses: () => {
    const request = new ListPropertyStatusesRequest();
    return new Promise((resolve) => {
      client.listPropertyStatuses(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject().propertyStatusesList)
        }
      });
    });
  },
  getPropertyStatus: (name) => {
    const request = new GetPropertyStatusRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.getPropertyStatus(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyPropertyStatus: (instance) => {
    const applying = tpPropertyStatusModel(instance)
    const request = new ApplyPropertyStatusRequest();
    request.setPropertyStatus(applying)
    return new Promise((resolve) => {
      client.applyPropertyStatus(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deletePropertyStatus: (name) => {
    const request = new DeletePropertyStatusRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.deletePropertyStatus(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
