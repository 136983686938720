// source: projects/erp/service/api/v1/model/property.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var projects_erp_service_api_v1_model_list_of_strings_pb = require('../list_of_strings_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_list_of_strings_pb);
var projects_erp_service_api_v1_model_money_pb = require('../money_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_money_pb);
var projects_erp_service_api_v1_model_unit_pb = require('../unit_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_unit_pb);
goog.exportSymbol('proto.qowner.erp.api.v1.model.Property', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.model.PropertyLocation', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.model.Property = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qowner.erp.api.v1.model.Property.repeatedFields_, null);
};
goog.inherits(proto.qowner.erp.api.v1.model.Property, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.model.Property.displayName = 'proto.qowner.erp.api.v1.model.Property';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.model.PropertyLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.model.PropertyLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.model.PropertyLocation.displayName = 'proto.qowner.erp.api.v1.model.PropertyLocation';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qowner.erp.api.v1.model.Property.repeatedFields_ = [26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.model.Property.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.model.Property.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.model.Property} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.model.Property.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    number: jspb.Message.getFieldWithDefault(msg, 3, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    developerName: (f = msg.getDeveloperName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    projectName: (f = msg.getProjectName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    projectDisplayName: (f = msg.getProjectDisplayName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    externalNumber: (f = msg.getExternalNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    externalSystem: (f = msg.getExternalSystem()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    externalUrl: (f = msg.getExternalUrl()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isProject: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    isFeatured: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    typeName: jspb.Message.getFieldWithDefault(msg, 14, ""),
    forSale: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    forRent: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    statusName: jspb.Message.getFieldWithDefault(msg, 17, ""),
    amount: (f = msg.getAmount()) && projects_erp_service_api_v1_model_money_pb.Money.toObject(includeInstance, f),
    vat: jspb.Message.getBooleanFieldWithDefault(msg, 19, false),
    location: (f = msg.getLocation()) && proto.qowner.erp.api.v1.model.PropertyLocation.toObject(includeInstance, f),
    valuesMap: (f = msg.getValuesMap()) ? f.toObject(includeInstance, undefined) : [],
    amenitiesMap: (f = msg.getAmenitiesMap()) ? f.toObject(includeInstance, undefined) : [],
    unitsMap: (f = msg.getUnitsMap()) ? f.toObject(includeInstance, proto.qowner.erp.api.v1.model.Unit.toObject) : [],
    photosMap: (f = msg.getPhotosMap()) ? f.toObject(includeInstance, proto.qowner.erp.api.v1.model.ListOfStrings.toObject) : [],
    project: (f = msg.getProject()) && proto.qowner.erp.api.v1.model.Property.toObject(includeInstance, f),
    supportedTypeNamesList: (f = jspb.Message.getRepeatedField(msg, 26)) == null ? undefined : f,
    unitCount: jspb.Message.getFieldWithDefault(msg, 27, 0),
    isVerified: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    isUpdated: jspb.Message.getBooleanFieldWithDefault(msg, 29, false),
    paymentPlanDescription: (f = msg.getPaymentPlanDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    note: (f = msg.getNote()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    publicDescription: (f = msg.getPublicDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.model.Property}
 */
proto.qowner.erp.api.v1.model.Property.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.model.Property;
  return proto.qowner.erp.api.v1.model.Property.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.model.Property} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.model.Property}
 */
proto.qowner.erp.api.v1.model.Property.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDeveloperName(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setProjectName(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setProjectDisplayName(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setExternalNumber(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setExternalSystem(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setExternalUrl(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsProject(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFeatured(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeName(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForSale(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForRent(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusName(value);
      break;
    case 18:
      var value = new projects_erp_service_api_v1_model_money_pb.Money;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.Money.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setVat(value);
      break;
    case 20:
      var value = new proto.qowner.erp.api.v1.model.PropertyLocation;
      reader.readMessage(value,proto.qowner.erp.api.v1.model.PropertyLocation.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 21:
      var value = msg.getValuesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 22:
      var value = msg.getAmenitiesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 23:
      var value = msg.getUnitsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.qowner.erp.api.v1.model.Unit.deserializeBinaryFromReader, "", new proto.qowner.erp.api.v1.model.Unit());
         });
      break;
    case 24:
      var value = msg.getPhotosMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.qowner.erp.api.v1.model.ListOfStrings.deserializeBinaryFromReader, "", new proto.qowner.erp.api.v1.model.ListOfStrings());
         });
      break;
    case 25:
      var value = new proto.qowner.erp.api.v1.model.Property;
      reader.readMessage(value,proto.qowner.erp.api.v1.model.Property.deserializeBinaryFromReader);
      msg.setProject(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.addSupportedTypeNames(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUnitCount(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsVerified(value);
      break;
    case 29:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsUpdated(value);
      break;
    case 30:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPaymentPlanDescription(value);
      break;
    case 31:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setNote(value);
      break;
    case 32:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPublicDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.model.Property.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.model.Property.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.model.Property} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.model.Property.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDeveloperName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getProjectName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getProjectDisplayName();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExternalNumber();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExternalSystem();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExternalUrl();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsProject();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getIsFeatured();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getTypeName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getForSale();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getForRent();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getStatusName();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      projects_erp_service_api_v1_model_money_pb.Money.serializeBinaryToWriter
    );
  }
  f = message.getVat();
  if (f) {
    writer.writeBool(
      19,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.qowner.erp.api.v1.model.PropertyLocation.serializeBinaryToWriter
    );
  }
  f = message.getValuesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(21, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getAmenitiesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(22, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getUnitsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(23, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.qowner.erp.api.v1.model.Unit.serializeBinaryToWriter);
  }
  f = message.getPhotosMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(24, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.qowner.erp.api.v1.model.ListOfStrings.serializeBinaryToWriter);
  }
  f = message.getProject();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      proto.qowner.erp.api.v1.model.Property.serializeBinaryToWriter
    );
  }
  f = message.getSupportedTypeNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      26,
      f
    );
  }
  f = message.getUnitCount();
  if (f !== 0) {
    writer.writeUint32(
      27,
      f
    );
  }
  f = message.getIsVerified();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getIsUpdated();
  if (f) {
    writer.writeBool(
      29,
      f
    );
  }
  f = message.getPaymentPlanDescription();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f != null) {
    writer.writeMessage(
      31,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPublicDescription();
  if (f != null) {
    writer.writeMessage(
      32,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string number = 3;
 * @return {string}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string display_name = 4;
 * @return {string}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue developer_name = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getDeveloperName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
*/
proto.qowner.erp.api.v1.model.Property.prototype.setDeveloperName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearDeveloperName = function() {
  return this.setDeveloperName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.hasDeveloperName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue project_name = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getProjectName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
*/
proto.qowner.erp.api.v1.model.Property.prototype.setProjectName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearProjectName = function() {
  return this.setProjectName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.hasProjectName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue project_display_name = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getProjectDisplayName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
*/
proto.qowner.erp.api.v1.model.Property.prototype.setProjectDisplayName = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearProjectDisplayName = function() {
  return this.setProjectDisplayName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.hasProjectDisplayName = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue description = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
*/
proto.qowner.erp.api.v1.model.Property.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue external_number = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getExternalNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
*/
proto.qowner.erp.api.v1.model.Property.prototype.setExternalNumber = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearExternalNumber = function() {
  return this.setExternalNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.hasExternalNumber = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue external_system = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getExternalSystem = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
*/
proto.qowner.erp.api.v1.model.Property.prototype.setExternalSystem = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearExternalSystem = function() {
  return this.setExternalSystem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.hasExternalSystem = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue external_url = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getExternalUrl = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
*/
proto.qowner.erp.api.v1.model.Property.prototype.setExternalUrl = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearExternalUrl = function() {
  return this.setExternalUrl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.hasExternalUrl = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool is_project = 12;
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getIsProject = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.setIsProject = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool is_featured = 13;
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getIsFeatured = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.setIsFeatured = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string type_name = 14;
 * @return {string}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getTypeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.setTypeName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool for_sale = 15;
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getForSale = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.setForSale = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool for_rent = 16;
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getForRent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.setForRent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string status_name = 17;
 * @return {string}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getStatusName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.setStatusName = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional Money amount = 18;
 * @return {?proto.qowner.erp.api.v1.model.Money}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getAmount = function() {
  return /** @type{?proto.qowner.erp.api.v1.model.Money} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.Money, 18));
};


/**
 * @param {?proto.qowner.erp.api.v1.model.Money|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
*/
proto.qowner.erp.api.v1.model.Property.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional bool vat = 19;
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getVat = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.setVat = function(value) {
  return jspb.Message.setProto3BooleanField(this, 19, value);
};


/**
 * optional PropertyLocation location = 20;
 * @return {?proto.qowner.erp.api.v1.model.PropertyLocation}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getLocation = function() {
  return /** @type{?proto.qowner.erp.api.v1.model.PropertyLocation} */ (
    jspb.Message.getWrapperField(this, proto.qowner.erp.api.v1.model.PropertyLocation, 20));
};


/**
 * @param {?proto.qowner.erp.api.v1.model.PropertyLocation|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
*/
proto.qowner.erp.api.v1.model.Property.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * map<string, string> values = 21;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getValuesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 21, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearValuesMap = function() {
  this.getValuesMap().clear();
  return this;};


/**
 * map<string, string> amenities = 22;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getAmenitiesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 22, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearAmenitiesMap = function() {
  this.getAmenitiesMap().clear();
  return this;};


/**
 * map<string, Unit> units = 23;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.qowner.erp.api.v1.model.Unit>}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getUnitsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.qowner.erp.api.v1.model.Unit>} */ (
      jspb.Message.getMapField(this, 23, opt_noLazyCreate,
      proto.qowner.erp.api.v1.model.Unit));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearUnitsMap = function() {
  this.getUnitsMap().clear();
  return this;};


/**
 * map<string, ListOfStrings> photos = 24;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.qowner.erp.api.v1.model.ListOfStrings>}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getPhotosMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.qowner.erp.api.v1.model.ListOfStrings>} */ (
      jspb.Message.getMapField(this, 24, opt_noLazyCreate,
      proto.qowner.erp.api.v1.model.ListOfStrings));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearPhotosMap = function() {
  this.getPhotosMap().clear();
  return this;};


/**
 * optional Property project = 25;
 * @return {?proto.qowner.erp.api.v1.model.Property}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getProject = function() {
  return /** @type{?proto.qowner.erp.api.v1.model.Property} */ (
    jspb.Message.getWrapperField(this, proto.qowner.erp.api.v1.model.Property, 25));
};


/**
 * @param {?proto.qowner.erp.api.v1.model.Property|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
*/
proto.qowner.erp.api.v1.model.Property.prototype.setProject = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearProject = function() {
  return this.setProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.hasProject = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * repeated string supported_type_names = 26;
 * @return {!Array<string>}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getSupportedTypeNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 26));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.setSupportedTypeNamesList = function(value) {
  return jspb.Message.setField(this, 26, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.addSupportedTypeNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 26, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearSupportedTypeNamesList = function() {
  return this.setSupportedTypeNamesList([]);
};


/**
 * optional uint32 unit_count = 27;
 * @return {number}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getUnitCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.setUnitCount = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional bool is_verified = 28;
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getIsVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.setIsVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional bool is_updated = 29;
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getIsUpdated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 29, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.setIsUpdated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 29, value);
};


/**
 * optional google.protobuf.StringValue payment_plan_description = 30;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getPaymentPlanDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 30));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
*/
proto.qowner.erp.api.v1.model.Property.prototype.setPaymentPlanDescription = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearPaymentPlanDescription = function() {
  return this.setPaymentPlanDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.hasPaymentPlanDescription = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional google.protobuf.StringValue note = 31;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getNote = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 31));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
*/
proto.qowner.erp.api.v1.model.Property.prototype.setNote = function(value) {
  return jspb.Message.setWrapperField(this, 31, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearNote = function() {
  return this.setNote(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.hasNote = function() {
  return jspb.Message.getField(this, 31) != null;
};


/**
 * optional google.protobuf.StringValue public_description = 32;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Property.prototype.getPublicDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 32));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
*/
proto.qowner.erp.api.v1.model.Property.prototype.setPublicDescription = function(value) {
  return jspb.Message.setWrapperField(this, 32, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Property} returns this
 */
proto.qowner.erp.api.v1.model.Property.prototype.clearPublicDescription = function() {
  return this.setPublicDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Property.prototype.hasPublicDescription = function() {
  return jspb.Message.getField(this, 32) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.model.PropertyLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.model.PropertyLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.model.PropertyLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    countryName: (f = msg.getCountryName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    stateName: (f = msg.getStateName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    cityName: (f = msg.getCityName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    zip: (f = msg.getZip()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    addressLine1: (f = msg.getAddressLine1()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    addressLine2: (f = msg.getAddressLine2()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    appartment: (f = msg.getAppartment()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    districtName: (f = msg.getDistrictName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    settlementName: (f = msg.getSettlementName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    quarterName: (f = msg.getQuarterName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    latitude: (f = msg.getLatitude()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f),
    longitude: (f = msg.getLongitude()) && google_protobuf_wrappers_pb.DoubleValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.model.PropertyLocation;
  return proto.qowner.erp.api.v1.model.PropertyLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.model.PropertyLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCountryName(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStateName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCityName(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setZip(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAddressLine1(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAddressLine2(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAppartment(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDistrictName(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSettlementName(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setQuarterName(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.DoubleValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
      msg.setLatitude(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.DoubleValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.DoubleValue.deserializeBinaryFromReader);
      msg.setLongitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.model.PropertyLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.model.PropertyLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.model.PropertyLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountryName();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStateName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCityName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getZip();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAddressLine1();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAddressLine2();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getAppartment();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDistrictName();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSettlementName();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getQuarterName();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLatitude();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
  f = message.getLongitude();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.DoubleValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue country_name = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.getCountryName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
*/
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.setCountryName = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.clearCountryName = function() {
  return this.setCountryName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.hasCountryName = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue state_name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.getStateName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
*/
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.setStateName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.clearStateName = function() {
  return this.setStateName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.hasStateName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue city_name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.getCityName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
*/
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.setCityName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.clearCityName = function() {
  return this.setCityName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.hasCityName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue zip = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.getZip = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
*/
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.setZip = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.clearZip = function() {
  return this.setZip(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.hasZip = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue address_line1 = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.getAddressLine1 = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
*/
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.setAddressLine1 = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.clearAddressLine1 = function() {
  return this.setAddressLine1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.hasAddressLine1 = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue address_line2 = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.getAddressLine2 = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
*/
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.setAddressLine2 = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.clearAddressLine2 = function() {
  return this.setAddressLine2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.hasAddressLine2 = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue appartment = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.getAppartment = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
*/
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.setAppartment = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.clearAppartment = function() {
  return this.setAppartment(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.hasAppartment = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue district_name = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.getDistrictName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
*/
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.setDistrictName = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.clearDistrictName = function() {
  return this.setDistrictName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.hasDistrictName = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue settlement_name = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.getSettlementName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
*/
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.setSettlementName = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.clearSettlementName = function() {
  return this.setSettlementName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.hasSettlementName = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue quarter_name = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.getQuarterName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
*/
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.setQuarterName = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.clearQuarterName = function() {
  return this.setQuarterName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.hasQuarterName = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.DoubleValue latitude = 11;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.getLatitude = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 11));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
*/
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.setLatitude = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.clearLatitude = function() {
  return this.setLatitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.hasLatitude = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.DoubleValue longitude = 12;
 * @return {?proto.google.protobuf.DoubleValue}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.getLongitude = function() {
  return /** @type{?proto.google.protobuf.DoubleValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.DoubleValue, 12));
};


/**
 * @param {?proto.google.protobuf.DoubleValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
*/
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.setLongitude = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.PropertyLocation} returns this
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.clearLongitude = function() {
  return this.setLongitude(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.PropertyLocation.prototype.hasLongitude = function() {
  return jspb.Message.getField(this, 12) != null;
};


goog.object.extend(exports, proto.qowner.erp.api.v1.model);
