import { ApiUrl, getMetadata } from '../index'
import { PropertyConfirmation } from './property_confirmation_pb'
import {
  GetPropertyConfirmationRequest,
  ListPropertyConfirmationsRequest,
  ApplyPropertyConfirmationRequest,
  DeletePropertyConfirmationRequest
} from './property_confirmations_pb'
import { PropertyConfirmationsClient } from './property_confirmations_grpc_web_pb'
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'

let client = new PropertyConfirmationsClient(ApiUrl, null, null);

const toPropertyConfirmationModel = (instance) => {
  let result = new PropertyConfirmation()
  if (instance.name)
    result.setName(instance.name)
  result.setPropertyName(instance.propertyName)
  result.setChecksList(instance.checksList)
  if (instance.note) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.note)
    result.setNote(wrapped)
  }
  return result
}

const toTypedObject = (instance) => {
  if (!instance)
    return null
  const result = {}
  Object.keys(instance).forEach(key => {
    if (key.endsWith('List') || key.endsWith('Map') || key === "amount") {
      result[key] = instance[key]
    } else if (instance[key]?.value) {
      result[key] = instance[key]?.value
    } else {
      result[key] = instance[key]
    }
  })
  return result
}

const API = {
  listPropertyConfirmations: (params) => {
    const request = new ListPropertyConfirmationsRequest()
    if (params.propertyName) {
      request.setPropertyName(params.propertyName)
    }
    return new Promise((resolve) => {
      client.listPropertyConfirmations(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject().propertyConfirmationsList.map(e => toTypedObject(e)))
        }
      });
    });
  },
  getPropertyConfirmation: (name) => {
    const request = new GetPropertyConfirmationRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.getPropertyConfirmation(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(toTypedObject(response.toObject()))
        }
      });
    });
  },
  applyPropertyConfirmation: (instance) => {
    const applying = toPropertyConfirmationModel(instance)
    const request = new ApplyPropertyConfirmationRequest();
    request.setPropertyConfirmation(applying)
    return new Promise((resolve) => {
      client.applyPropertyConfirmation(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(toTypedObject(response.toObject()))
        }
      })
    })
  },
  deletePropertyConfirmation: (name) => {
    const request = new DeletePropertyConfirmationRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.deletePropertyConfirmation(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
