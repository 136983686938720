/**
 * @fileoverview gRPC-Web generated client stub for qowner.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/property_confirmations.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var projects_erp_service_api_v1_model_property_confirmation_pb = require('./property_confirmation_pb.js')
const proto = {};
proto.qowner = {};
proto.qowner.erp = {};
proto.qowner.erp.api = {};
proto.qowner.erp.api.v1 = {};
proto.qowner.erp.api.v1.services = require('./property_confirmations_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.PropertyConfirmationsClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.PropertyConfirmationsPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest,
 *   !proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse>}
 */
const methodDescriptor_PropertyConfirmations_ListPropertyConfirmations = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyConfirmations/ListPropertyConfirmations',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest,
  proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyConfirmationsClient.prototype.listPropertyConfirmations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyConfirmations/ListPropertyConfirmations',
      request,
      metadata || {},
      methodDescriptor_PropertyConfirmations_ListPropertyConfirmations,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.ListPropertyConfirmationsResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyConfirmationsPromiseClient.prototype.listPropertyConfirmations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyConfirmations/ListPropertyConfirmations',
      request,
      metadata || {},
      methodDescriptor_PropertyConfirmations_ListPropertyConfirmations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest,
 *   !proto.qowner.erp.api.v1.model.PropertyConfirmation>}
 */
const methodDescriptor_PropertyConfirmations_GetPropertyConfirmation = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyConfirmations/GetPropertyConfirmation',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest,
  projects_erp_service_api_v1_model_property_confirmation_pb.PropertyConfirmation,
  /**
   * @param {!proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_property_confirmation_pb.PropertyConfirmation.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.PropertyConfirmation)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.PropertyConfirmation>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyConfirmationsClient.prototype.getPropertyConfirmation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyConfirmations/GetPropertyConfirmation',
      request,
      metadata || {},
      methodDescriptor_PropertyConfirmations_GetPropertyConfirmation,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyConfirmationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.PropertyConfirmation>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyConfirmationsPromiseClient.prototype.getPropertyConfirmation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyConfirmations/GetPropertyConfirmation',
      request,
      metadata || {},
      methodDescriptor_PropertyConfirmations_GetPropertyConfirmation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest,
 *   !proto.qowner.erp.api.v1.model.PropertyConfirmation>}
 */
const methodDescriptor_PropertyConfirmations_ApplyPropertyConfirmation = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyConfirmations/ApplyPropertyConfirmation',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest,
  projects_erp_service_api_v1_model_property_confirmation_pb.PropertyConfirmation,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_property_confirmation_pb.PropertyConfirmation.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.PropertyConfirmation)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.PropertyConfirmation>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyConfirmationsClient.prototype.applyPropertyConfirmation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyConfirmations/ApplyPropertyConfirmation',
      request,
      metadata || {},
      methodDescriptor_PropertyConfirmations_ApplyPropertyConfirmation,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyConfirmationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.PropertyConfirmation>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyConfirmationsPromiseClient.prototype.applyPropertyConfirmation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyConfirmations/ApplyPropertyConfirmation',
      request,
      metadata || {},
      methodDescriptor_PropertyConfirmations_ApplyPropertyConfirmation);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest,
 *   !proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse>}
 */
const methodDescriptor_PropertyConfirmations_DeletePropertyConfirmation = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyConfirmations/DeletePropertyConfirmation',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest,
  proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyConfirmationsClient.prototype.deletePropertyConfirmation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyConfirmations/DeletePropertyConfirmation',
      request,
      metadata || {},
      methodDescriptor_PropertyConfirmations_DeletePropertyConfirmation,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyConfirmationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.DeletePropertyConfirmationResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyConfirmationsPromiseClient.prototype.deletePropertyConfirmation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyConfirmations/DeletePropertyConfirmation',
      request,
      metadata || {},
      methodDescriptor_PropertyConfirmations_DeletePropertyConfirmation);
};


module.exports = proto.qowner.erp.api.v1.services;

