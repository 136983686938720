// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import API from 'api/customers'
import PropertyCollectionsAPI from 'api/property_collections'
import PropertyQueriesAPI from 'api/property_queries'

export const listCustomers = createAsyncThunk('appCustomers/listCustomers', async (params, { signal }) => {
  const response = await API.listCustomers(params)
  if (signal.aborted)
    return
  return {
    params,
    data: response.customersList,
    totalRows: response.totalRows
  }
})

export const getAllCustomers = createAsyncThunk('appCustomers/getAllCustomers', async () => {
  const response = await API.listCustomers({})
  return response.customersList
})

export const getCustomer = createAsyncThunk('appCustomers/getCustomer', async (name, { dispatch, getState }) => {
  const loaded = (getState().customers.data ?? []).filter(e => e.name === name)[0]
  if (loaded)
    await dispatch(appCustomers.actions.setSelected(loaded))
  return await API.getCustomer(name)
})

export const addCustomer = createAsyncThunk('appCustomers/addCustomer', async (instance) => {
  const updated = await API.applyCustomer(instance)
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const modifyCustomer = createAsyncThunk('appCustomers/modifyCustomer', async (instance, { dispatch, getState }) => {
  const updated = await API.applyCustomer(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appCustomers.actions.setSelected(instance))
  await dispatch(listCustomers(getState().customers.params))
  return instance
})

export const deleteCustomer = createAsyncThunk('appCustomers/deleteCustomer', async (name) => {
  await API.deleteCustomer(name)
  return name
})

export const listPropertyCollections = createAsyncThunk('appCustomers/listPropertyCollections', async (params, { signal }) => {
  const response = await PropertyCollectionsAPI.listPropertyCollections(params)
  if (signal.aborted)
    return
  return {
    params,
    data: response.propertyCollectionsList,
    totalRows: response.totalRows
  }
})

export const getPropertyCollection = createAsyncThunk('appCustomers/getPropertyCollection',  async name => {
  return await PropertyCollectionsAPI.getPropertyCollection(name)
})

export const addPropertyCollection = createAsyncThunk('appCustomers/addPropertyCollection', async (instance) => {
  const updated = await PropertyCollectionsAPI.applyPropertyCollection(instance)
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const modifyPropertyCollection = createAsyncThunk('appCustomers/modifyPropertyCollection', async (instance) => {
  const updated = await PropertyCollectionsAPI.applyPropertyCollection(instance)
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const deletePropertyCollection = createAsyncThunk('appCustomers/deletePropertyCollection', async (name) => {
  await PropertyCollectionsAPI.deletePropertyCollection(name)
  return name
})

export const listPropertyQueries = createAsyncThunk('appCustomers/listPropertyQueries', async (params, { signal }) => {
  const response = await PropertyQueriesAPI.listPropertyQueries(params)
  if (signal.aborted)
    return
  return {
    params,
    data: response.propertyQueriesList,
    totalRows: response.totalRows
  }
})

export const getPropertyQuery = createAsyncThunk('appCustomers/getPropertyQuery',  async name => {
  return await PropertyQueriesAPI.getPropertyQuery(name)
})

export const addPropertyQuery = createAsyncThunk('appCustomers/addPropertyQuery', async (instance) => {
  const updated = await PropertyQueriesAPI.applyPropertyQuery(instance)
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const modifyPropertyQuery = createAsyncThunk('appCustomers/modifyPropertyQuery', async (instance) => {
  const updated = await PropertyQueriesAPI.applyPropertyQuery(instance)
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const deletePropertyQuery = createAsyncThunk('appCustomers/deletePropertyQuery', async (name) => {
  await PropertyQueriesAPI.deletePropertyQuery(name)
  return name
})

export const appCustomers = createSlice({
  name: 'appCustomers',
  initialState: {
    data: [],
    allData: [],
    total: 1,
    params: {},
    images: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(listCustomers.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(getAllCustomers.fulfilled, (state, { payload }) => {
        state.allData = payload
      })
      .addCase(getCustomer.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appCustomers.reducer
