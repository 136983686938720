// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/property_types'

export const list = createAsyncThunk('appPropertyTypes/list', async () => {
  return await API.listPropertyTypes()
})

export const get = createAsyncThunk('appPropertyTypes/get', async name => {
  return await API.getPropertyType(name)
})

export const add = createAsyncThunk('appPropertyTypes/add', async (instance, { dispatch }) => {
  const updated = await API.applyPropertyType(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(list())
  return instance
})

export const modify = createAsyncThunk('appPropertyTypes/modify', async (instance, { dispatch }) => {
  const updated = await API.applyPropertyType(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appPropertyTypesSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const del = createAsyncThunk('appPropertyTypes/del', async (name, { dispatch }) => {
  await API.deletePropertyType(name)
  await dispatch(list())
  return name
})

export const appPropertyTypesSlice = createSlice({
  name: 'appPropertyTypes',
  initialState: {
    data: []
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appPropertyTypesSlice.reducer
