// source: projects/erp/service/api/v1/model/customer.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.qowner.erp.api.v1.model.Customer', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.model.Customer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.model.Customer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.model.Customer.displayName = 'proto.qowner.erp.api.v1.model.Customer';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.model.Customer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.model.Customer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.model.Customer.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    number: jspb.Message.getFieldWithDefault(msg, 4, ""),
    firstName: (f = msg.getFirstName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    middleName: (f = msg.getMiddleName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastName: (f = msg.getLastName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    sex: (f = msg.getSex()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    email: (f = msg.getEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    mobile: (f = msg.getMobile()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    mobile2: (f = msg.getMobile2()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    languageName: (f = msg.getLanguageName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    countryName: (f = msg.getCountryName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.model.Customer}
 */
proto.qowner.erp.api.v1.model.Customer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.model.Customer;
  return proto.qowner.erp.api.v1.model.Customer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.model.Customer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.model.Customer}
 */
proto.qowner.erp.api.v1.model.Customer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNumber(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstName(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMiddleName(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastName(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSex(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMobile(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMobile2(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLanguageName(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCountryName(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.model.Customer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.model.Customer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.model.Customer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFirstName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMiddleName();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastName();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSex();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMobile();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMobile2();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLanguageName();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getCountryName();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
 */
proto.qowner.erp.api.v1.model.Customer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 id = 2;
 * @return {number}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
 */
proto.qowner.erp.api.v1.model.Customer.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
 */
proto.qowner.erp.api.v1.model.Customer.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string number = 4;
 * @return {string}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.getNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
 */
proto.qowner.erp.api.v1.model.Customer.prototype.setNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue first_name = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.getFirstName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
*/
proto.qowner.erp.api.v1.model.Customer.prototype.setFirstName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
 */
proto.qowner.erp.api.v1.model.Customer.prototype.clearFirstName = function() {
  return this.setFirstName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.hasFirstName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue middle_name = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.getMiddleName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
*/
proto.qowner.erp.api.v1.model.Customer.prototype.setMiddleName = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
 */
proto.qowner.erp.api.v1.model.Customer.prototype.clearMiddleName = function() {
  return this.setMiddleName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.hasMiddleName = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue last_name = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.getLastName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
*/
proto.qowner.erp.api.v1.model.Customer.prototype.setLastName = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
 */
proto.qowner.erp.api.v1.model.Customer.prototype.clearLastName = function() {
  return this.setLastName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.hasLastName = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue sex = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.getSex = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
*/
proto.qowner.erp.api.v1.model.Customer.prototype.setSex = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
 */
proto.qowner.erp.api.v1.model.Customer.prototype.clearSex = function() {
  return this.setSex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.hasSex = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue email = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.getEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
*/
proto.qowner.erp.api.v1.model.Customer.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
 */
proto.qowner.erp.api.v1.model.Customer.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue mobile = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.getMobile = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
*/
proto.qowner.erp.api.v1.model.Customer.prototype.setMobile = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
 */
proto.qowner.erp.api.v1.model.Customer.prototype.clearMobile = function() {
  return this.setMobile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.hasMobile = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue mobile2 = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.getMobile2 = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
*/
proto.qowner.erp.api.v1.model.Customer.prototype.setMobile2 = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
 */
proto.qowner.erp.api.v1.model.Customer.prototype.clearMobile2 = function() {
  return this.setMobile2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.hasMobile2 = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue language_name = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.getLanguageName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
*/
proto.qowner.erp.api.v1.model.Customer.prototype.setLanguageName = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
 */
proto.qowner.erp.api.v1.model.Customer.prototype.clearLanguageName = function() {
  return this.setLanguageName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.hasLanguageName = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue country_name = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.getCountryName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
*/
proto.qowner.erp.api.v1.model.Customer.prototype.setCountryName = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
 */
proto.qowner.erp.api.v1.model.Customer.prototype.clearCountryName = function() {
  return this.setCountryName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.hasCountryName = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string status = 14;
 * @return {string}
 */
proto.qowner.erp.api.v1.model.Customer.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.model.Customer} returns this
 */
proto.qowner.erp.api.v1.model.Customer.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


goog.object.extend(exports, proto.qowner.erp.api.v1.model);
