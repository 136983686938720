import wrappers from 'google-protobuf/google/protobuf/wrappers_pb'
import { ApiUrl, getMetadata } from '../index'
import { PropertyCollection, PropertyCollectionProperty } from './property_collection_pb'
import {
  GetPropertyCollectionRequest,
  ListPropertyCollectionsRequest,
  ApplyPropertyCollectionRequest,
  DeletePropertyCollectionRequest
} from './property_collections_pb'
import { PropertyCollectionsClient } from './property_collections_grpc_web_pb'

let client = new PropertyCollectionsClient(ApiUrl, null, null);

const toPropertyCollectionModel = (instance) => {
  let result = new PropertyCollection()
  if (instance.name)
    result.setName(instance.name)
  result.setId(instance.id)
  result.setDisplayName(instance.displayName)
  if (instance.customerNamesList) {
    result.setCustomerNamesList(instance.customerNamesList)
  }
  result.setIsActive(instance.isActive)
  if (instance.propertiesList) {
    const props = []
    for (const prop of instance.propertiesList) {
      const pcp = new PropertyCollectionProperty()
      if (prop.name) {
        pcp.setName(prop.name)
      }
      pcp.setPropertyName(prop.propertyName)
      if (pcp.description) {
        const wrapped = new wrappers.StringValue()
        wrapped.setValue(prop.description)
        pcp.setDescription(wrapped)
      }
      props.push(pcp)
    }
    result.setPropertiesList(props)
  }

  return result
}

const API = {
  listPropertyCollections: (params) => {
    const request = new ListPropertyCollectionsRequest();
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    if (params?.customerName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.customerName)
      request.setCustomerName(wrapped)
    }
    return new Promise((resolve) => {
      client.listPropertyCollections(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getPropertyCollection: (name) => {
    const request = new GetPropertyCollectionRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.getPropertyCollection(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyPropertyCollection: (instance) => {
    const applying = toPropertyCollectionModel(instance)
    const request = new ApplyPropertyCollectionRequest();
    request.setPropertyCollection(applying)
    return new Promise((resolve) => {
      client.applyPropertyCollection(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deletePropertyCollection: (name) => {
    const request = new DeletePropertyCollectionRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.deletePropertyCollection(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
