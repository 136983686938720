// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/tasks'

export const list = createAsyncThunk('appTasks/list', async (params) => {
  const response = await API.listTasks(params)

  return {
    params,
    data: response.tasksList,
    totalRows: response.totalRows
  }
})

export const get = createAsyncThunk('appTasks/get', async name => {
  return await API.getTask(name)
})

export const add = createAsyncThunk('appTasks/add', async (instance, { dispatch, getState }) => {
  const updated = await API.applyTask(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(list(getState().tasks.params))
  return instance
})

export const modify = createAsyncThunk('appTasks/modify', async (instance, { dispatch, getState }) => {
  const updated = await API.applyTask(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appTasksSlice.actions.setSelected(instance))
  await dispatch(list(getState().tasks.params))
})

export const del = createAsyncThunk('appTasks/del', async (name, { dispatch, getState }) => {
  await API.deleteTask(name)
  await dispatch(list(getState().tasks.params))
  return name
})

export const appTasksSlice = createSlice({
  name: 'appTasks',
  initialState: {
    data: [],
    total: 1,
    params: {},
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appTasksSlice.reducer
