// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from 'api/locations'

export const list = createAsyncThunk('appCities/list', async (_, { getState }) => {
  let cities = await API.listCities()
  let states = getState().states
  let countries = getState().countries
  cities.forEach((e) => {
    e.stateDisplayName = states?.data?.filter((c) => c.name === e.stateName)[0]?.displayName
    e.countryDisplayName = countries?.data?.filter((c) => c.name === e.countryName)[0]?.displayName
  })
  return cities
})

export const add = createAsyncThunk('appCities/add', async (instance, { dispatch }) => {
  const updated = await API.applyCity(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(list())
  return instance
})

export const get = createAsyncThunk('appCities/get', async name => {
  return await API.getCity(name)
})

export const modify = createAsyncThunk('appCities/modify', async (instance, { dispatch }) => {
  const updated = await API.applyCity(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appCitiesSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const appCitiesSlice = createSlice({
  name: 'appCities',
  initialState: {
    data: []
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appCitiesSlice.reducer
