// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/ai_prompts'

export const list = createAsyncThunk('appAiPrompts/list', async () => {
  return await API.listChatGptConfigurations()
})

export const get = createAsyncThunk('appAiPrompts/get', async name => {
  return await API.getChatGptConfiguration(name)
})

export const add = createAsyncThunk('appAiPrompts/add', async (instance, { dispatch }) => {
  const updated = await API.applyChatGptConfiguration(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(list())
  return instance
})

export const modify = createAsyncThunk('appAiPrompts/modify', async (instance, { dispatch }) => {
  const updated = await API.applyChatGptConfiguration(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appAiPromptsSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const appAiPromptsSlice = createSlice({
  name: 'appAiPrompts',
  initialState: {
    data: []
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appAiPromptsSlice.reducer
