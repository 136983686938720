import { ApiUrl, getMetadata } from '../index'
import { TaskType } from './task_type_pb'
import {
  GetTaskTypeRequest,
  ListTaskTypesRequest,
  ApplyTaskTypeRequest,
  DeleteTaskTypeRequest
} from './task_types_pb'
import { TaskTypesClient } from './task_types_grpc_web_pb'

let client = new TaskTypesClient(ApiUrl, null, null);

const toTaskTypeModel = (instance) => {
  let result = new TaskType()
  if (instance.name)
    result.setName(instance.name)
  result.setCode(instance.code)
  result.setDisplayName(instance.displayName)
  result.setDescription(instance.description)
  return result
}

const API = {
  listTaskTypes: () => {
    const request = new ListTaskTypesRequest();
    return new Promise((resolve) => {
      client.listTaskTypes(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject().taskTypesList)
        }
      });
    });
  },
  getTaskType: (name) => {
    const request = new GetTaskTypeRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.getTaskType(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyTaskType: (instance) => {
    const applying = toTaskTypeModel(instance)
    const request = new ApplyTaskTypeRequest();
    request.setTaskType(applying)
    return new Promise((resolve) => {
      client.applyTaskType(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteTaskType: (name) => {
    const request = new DeleteTaskTypeRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.deleteTaskType(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
