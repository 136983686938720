/**
 * @fileoverview gRPC-Web generated client stub for qowner.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/developers.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

var projects_erp_service_api_v1_model_developer_pb = require('./developer_pb.js')
const proto = {};
proto.qowner = {};
proto.qowner.erp = {};
proto.qowner.erp.api = {};
proto.qowner.erp.api.v1 = {};
proto.qowner.erp.api.v1.services = require('./developers_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.DevelopersClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.DevelopersPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ListDevelopersRequest,
 *   !proto.qowner.erp.api.v1.services.ListDevelopersResponse>}
 */
const methodDescriptor_Developers_ListDevelopers = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Developers/ListDevelopers',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ListDevelopersRequest,
  proto.qowner.erp.api.v1.services.ListDevelopersResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ListDevelopersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.ListDevelopersResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ListDevelopersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.ListDevelopersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.ListDevelopersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.DevelopersClient.prototype.listDevelopers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Developers/ListDevelopers',
      request,
      metadata || {},
      methodDescriptor_Developers_ListDevelopers,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ListDevelopersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.ListDevelopersResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.DevelopersPromiseClient.prototype.listDevelopers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Developers/ListDevelopers',
      request,
      metadata || {},
      methodDescriptor_Developers_ListDevelopers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.GetDeveloperRequest,
 *   !proto.qowner.erp.api.v1.model.Developer>}
 */
const methodDescriptor_Developers_GetDeveloper = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Developers/GetDeveloper',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.GetDeveloperRequest,
  projects_erp_service_api_v1_model_developer_pb.Developer,
  /**
   * @param {!proto.qowner.erp.api.v1.services.GetDeveloperRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_developer_pb.Developer.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.GetDeveloperRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.Developer)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.Developer>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.DevelopersClient.prototype.getDeveloper =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Developers/GetDeveloper',
      request,
      metadata || {},
      methodDescriptor_Developers_GetDeveloper,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.GetDeveloperRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.Developer>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.DevelopersPromiseClient.prototype.getDeveloper =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Developers/GetDeveloper',
      request,
      metadata || {},
      methodDescriptor_Developers_GetDeveloper);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ApplyDeveloperRequest,
 *   !proto.qowner.erp.api.v1.model.Developer>}
 */
const methodDescriptor_Developers_ApplyDeveloper = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Developers/ApplyDeveloper',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ApplyDeveloperRequest,
  projects_erp_service_api_v1_model_developer_pb.Developer,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ApplyDeveloperRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_developer_pb.Developer.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyDeveloperRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.Developer)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.Developer>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.DevelopersClient.prototype.applyDeveloper =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Developers/ApplyDeveloper',
      request,
      metadata || {},
      methodDescriptor_Developers_ApplyDeveloper,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyDeveloperRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.Developer>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.DevelopersPromiseClient.prototype.applyDeveloper =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Developers/ApplyDeveloper',
      request,
      metadata || {},
      methodDescriptor_Developers_ApplyDeveloper);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.DeleteDeveloperRequest,
 *   !proto.qowner.erp.api.v1.services.DeleteDeveloperResponse>}
 */
const methodDescriptor_Developers_DeleteDeveloper = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Developers/DeleteDeveloper',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.DeleteDeveloperRequest,
  proto.qowner.erp.api.v1.services.DeleteDeveloperResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.DeleteDeveloperRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.DeleteDeveloperResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.DeleteDeveloperRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.DeleteDeveloperResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.DeleteDeveloperResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.DevelopersClient.prototype.deleteDeveloper =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Developers/DeleteDeveloper',
      request,
      metadata || {},
      methodDescriptor_Developers_DeleteDeveloper,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.DeleteDeveloperRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.DeleteDeveloperResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.DevelopersPromiseClient.prototype.deleteDeveloper =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Developers/DeleteDeveloper',
      request,
      metadata || {},
      methodDescriptor_Developers_DeleteDeveloper);
};


module.exports = proto.qowner.erp.api.v1.services;

