// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import countries from 'views/locations/countries/store'
import states from 'views/locations/states/store'
import cities from 'views/locations/cities/store'
import quarters from 'views/locations/quarters/store'
import settlements from 'views/locations/settlements/store'
import districts from 'views/locations/districts/store'
import developers from 'views/properties/developers/store'
import projects from 'views/properties/projects/store'
import objects from 'views/properties/objects/store'
import propertyStatuses from 'views/references/property-statuses/store'
import propertyTypes from 'views/references/property-types/store'
import customers from 'views/users/customers/store'
import propertyCollections from 'views/properties/collections/store'
import taskTypes from 'views/references/task-types/store'
import tasks from 'views/activities/tasks/store'
import employees from 'views/users/employees/store'
import aiPrompts from 'views/references/ai-prompts/store'
import propertyLeads from 'views/properties/leads/store'
import emailTopics from 'views/activities/email-topics/store'
import email from 'views/activities/email/store'

const rootReducer = {
  auth,
  countries,
  states,
  cities,
  quarters,
  settlements,
  districts,

  developers,
  projects,
  objects,
  propertyStatuses,
  propertyTypes,
  propertyCollections,
  taskTypes,
  tasks,
  employees,
  propertyLeads,
  emailTopics,
  email,

  customers,

  aiPrompts,

  navbar,
  layout
}

export default rootReducer
