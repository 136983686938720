// source: projects/erp/service/api/v1/services/employees.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var projects_erp_service_api_v1_model_employee_pb = require('./employee_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_employee_pb);
goog.exportSymbol('proto.qowner.erp.api.v1.services.ApplyEmployeeRequest', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.DeleteEmployeeRequest', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.DeleteEmployeeResponse', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.GetEmployeeRequest', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.ListEmployeesRequest', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.ListEmployeesResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.ListEmployeesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.ListEmployeesRequest.displayName = 'proto.qowner.erp.api.v1.services.ListEmployeesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.ListEmployeesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qowner.erp.api.v1.services.ListEmployeesResponse.repeatedFields_, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.ListEmployeesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.ListEmployeesResponse.displayName = 'proto.qowner.erp.api.v1.services.ListEmployeesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.GetEmployeeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.GetEmployeeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.GetEmployeeRequest.displayName = 'proto.qowner.erp.api.v1.services.GetEmployeeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.ApplyEmployeeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.ApplyEmployeeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.ApplyEmployeeRequest.displayName = 'proto.qowner.erp.api.v1.services.ApplyEmployeeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.DeleteEmployeeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.DeleteEmployeeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.DeleteEmployeeRequest.displayName = 'proto.qowner.erp.api.v1.services.DeleteEmployeeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.DeleteEmployeeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.DeleteEmployeeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.DeleteEmployeeResponse.displayName = 'proto.qowner.erp.api.v1.services.DeleteEmployeeResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.ListEmployeesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.ListEmployeesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sortColumn: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sort: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.ListEmployeesRequest}
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.ListEmployeesRequest;
  return proto.qowner.erp.api.v1.services.ListEmployeesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.ListEmployeesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.ListEmployeesRequest}
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortColumn(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSort(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.ListEmployeesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.ListEmployeesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSortColumn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSort();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qowner.erp.api.v1.services.ListEmployeesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 per_page = 2;
 * @return {number}
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qowner.erp.api.v1.services.ListEmployeesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string sort_column = 3;
 * @return {string}
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.prototype.getSortColumn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.services.ListEmployeesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.prototype.setSortColumn = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool sort = 4;
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.prototype.getSort = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qowner.erp.api.v1.services.ListEmployeesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.prototype.setSort = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue search = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ListEmployeesRequest} returns this
*/
proto.qowner.erp.api.v1.services.ListEmployeesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ListEmployeesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListEmployeesRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qowner.erp.api.v1.services.ListEmployeesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.ListEmployeesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.ListEmployeesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.ListEmployeesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ListEmployeesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    employeesList: jspb.Message.toObjectList(msg.getEmployeesList(),
    projects_erp_service_api_v1_model_employee_pb.Employee.toObject, includeInstance),
    totalRows: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.ListEmployeesResponse}
 */
proto.qowner.erp.api.v1.services.ListEmployeesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.ListEmployeesResponse;
  return proto.qowner.erp.api.v1.services.ListEmployeesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.ListEmployeesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.ListEmployeesResponse}
 */
proto.qowner.erp.api.v1.services.ListEmployeesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_employee_pb.Employee;
      reader.readMessage(value,projects_erp_service_api_v1_model_employee_pb.Employee.deserializeBinaryFromReader);
      msg.addEmployees(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRows(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.ListEmployeesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.ListEmployeesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.ListEmployeesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ListEmployeesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmployeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      projects_erp_service_api_v1_model_employee_pb.Employee.serializeBinaryToWriter
    );
  }
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated qowner.erp.api.v1.model.Employee employees = 1;
 * @return {!Array<!proto.qowner.erp.api.v1.model.Employee>}
 */
proto.qowner.erp.api.v1.services.ListEmployeesResponse.prototype.getEmployeesList = function() {
  return /** @type{!Array<!proto.qowner.erp.api.v1.model.Employee>} */ (
    jspb.Message.getRepeatedWrapperField(this, projects_erp_service_api_v1_model_employee_pb.Employee, 1));
};


/**
 * @param {!Array<!proto.qowner.erp.api.v1.model.Employee>} value
 * @return {!proto.qowner.erp.api.v1.services.ListEmployeesResponse} returns this
*/
proto.qowner.erp.api.v1.services.ListEmployeesResponse.prototype.setEmployeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qowner.erp.api.v1.model.Employee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qowner.erp.api.v1.model.Employee}
 */
proto.qowner.erp.api.v1.services.ListEmployeesResponse.prototype.addEmployees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qowner.erp.api.v1.model.Employee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qowner.erp.api.v1.services.ListEmployeesResponse} returns this
 */
proto.qowner.erp.api.v1.services.ListEmployeesResponse.prototype.clearEmployeesList = function() {
  return this.setEmployeesList([]);
};


/**
 * optional int64 total_rows = 2;
 * @return {number}
 */
proto.qowner.erp.api.v1.services.ListEmployeesResponse.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qowner.erp.api.v1.services.ListEmployeesResponse} returns this
 */
proto.qowner.erp.api.v1.services.ListEmployeesResponse.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 total_pages = 3;
 * @return {number}
 */
proto.qowner.erp.api.v1.services.ListEmployeesResponse.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qowner.erp.api.v1.services.ListEmployeesResponse} returns this
 */
proto.qowner.erp.api.v1.services.ListEmployeesResponse.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.GetEmployeeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.GetEmployeeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.GetEmployeeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.GetEmployeeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.GetEmployeeRequest}
 */
proto.qowner.erp.api.v1.services.GetEmployeeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.GetEmployeeRequest;
  return proto.qowner.erp.api.v1.services.GetEmployeeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.GetEmployeeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.GetEmployeeRequest}
 */
proto.qowner.erp.api.v1.services.GetEmployeeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.GetEmployeeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.GetEmployeeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.GetEmployeeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.GetEmployeeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qowner.erp.api.v1.services.GetEmployeeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.services.GetEmployeeRequest} returns this
 */
proto.qowner.erp.api.v1.services.GetEmployeeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.ApplyEmployeeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.ApplyEmployeeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.ApplyEmployeeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ApplyEmployeeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    employee: (f = msg.getEmployee()) && projects_erp_service_api_v1_model_employee_pb.Employee.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.ApplyEmployeeRequest}
 */
proto.qowner.erp.api.v1.services.ApplyEmployeeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.ApplyEmployeeRequest;
  return proto.qowner.erp.api.v1.services.ApplyEmployeeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.ApplyEmployeeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.ApplyEmployeeRequest}
 */
proto.qowner.erp.api.v1.services.ApplyEmployeeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_employee_pb.Employee;
      reader.readMessage(value,projects_erp_service_api_v1_model_employee_pb.Employee.deserializeBinaryFromReader);
      msg.setEmployee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.ApplyEmployeeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.ApplyEmployeeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.ApplyEmployeeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ApplyEmployeeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmployee();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      projects_erp_service_api_v1_model_employee_pb.Employee.serializeBinaryToWriter
    );
  }
};


/**
 * optional qowner.erp.api.v1.model.Employee employee = 1;
 * @return {?proto.qowner.erp.api.v1.model.Employee}
 */
proto.qowner.erp.api.v1.services.ApplyEmployeeRequest.prototype.getEmployee = function() {
  return /** @type{?proto.qowner.erp.api.v1.model.Employee} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_employee_pb.Employee, 1));
};


/**
 * @param {?proto.qowner.erp.api.v1.model.Employee|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ApplyEmployeeRequest} returns this
*/
proto.qowner.erp.api.v1.services.ApplyEmployeeRequest.prototype.setEmployee = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ApplyEmployeeRequest} returns this
 */
proto.qowner.erp.api.v1.services.ApplyEmployeeRequest.prototype.clearEmployee = function() {
  return this.setEmployee(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ApplyEmployeeRequest.prototype.hasEmployee = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.DeleteEmployeeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.DeleteEmployeeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.DeleteEmployeeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.DeleteEmployeeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.DeleteEmployeeRequest}
 */
proto.qowner.erp.api.v1.services.DeleteEmployeeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.DeleteEmployeeRequest;
  return proto.qowner.erp.api.v1.services.DeleteEmployeeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.DeleteEmployeeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.DeleteEmployeeRequest}
 */
proto.qowner.erp.api.v1.services.DeleteEmployeeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.DeleteEmployeeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.DeleteEmployeeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.DeleteEmployeeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.DeleteEmployeeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qowner.erp.api.v1.services.DeleteEmployeeRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.services.DeleteEmployeeRequest} returns this
 */
proto.qowner.erp.api.v1.services.DeleteEmployeeRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.DeleteEmployeeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.DeleteEmployeeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.DeleteEmployeeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.DeleteEmployeeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.DeleteEmployeeResponse}
 */
proto.qowner.erp.api.v1.services.DeleteEmployeeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.DeleteEmployeeResponse;
  return proto.qowner.erp.api.v1.services.DeleteEmployeeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.DeleteEmployeeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.DeleteEmployeeResponse}
 */
proto.qowner.erp.api.v1.services.DeleteEmployeeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.DeleteEmployeeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.DeleteEmployeeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.DeleteEmployeeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.DeleteEmployeeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.qowner.erp.api.v1.services);
