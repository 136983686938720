import { ApiUrl, getMetadata } from '../index'
import { ChatGptConfiguration } from './chat_gpt_configuration_pb'
import {
  GetChatGptConfigurationRequest,
  ListChatGptConfigurationsRequest,
  ApplyChatGptConfigurationRequest
} from './chat_gpt_configurations_pb'
import { ChatGptConfigurationClient } from './chat_gpt_configurations_grpc_web_pb'

let client = new ChatGptConfigurationClient(ApiUrl, null, null);

const toChatGptConfigurationModel = (instance) => {
  let result = new ChatGptConfiguration();
  if (instance.name)
    result.setName(instance.name)
  result.setCode(instance.code)
  result.setPrompt(instance.prompt)
  result.setIsActive(instance.isActive)
  return result
}

const API = {
  listChatGptConfigurations: () => {
    const request = new ListChatGptConfigurationsRequest();
    return new Promise((resolve) => {
      client.listChatGptConfigurations(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject().chatGptConfigurationsList)
        }
      });
    });
  },
  getChatGptConfiguration: (name) => {
    const request = new GetChatGptConfigurationRequest();
    request.setName(name)
    return new Promise((resolve) => {
      client.getChatGptConfiguration(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyChatGptConfiguration: (instance) => {
    const applying = toChatGptConfigurationModel(instance)
    const request = new ApplyChatGptConfigurationRequest();
    request.setChatGptConfiguration(applying)
    return new Promise((resolve) => {
      client.applyChatGptConfiguration(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  }
};

export default API;
