/**
 * @fileoverview gRPC-Web generated client stub for qowner.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/employees.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var projects_erp_service_api_v1_model_employee_pb = require('./employee_pb.js')
const proto = {};
proto.qowner = {};
proto.qowner.erp = {};
proto.qowner.erp.api = {};
proto.qowner.erp.api.v1 = {};
proto.qowner.erp.api.v1.services = require('./employees_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.EmployeesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.EmployeesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ListEmployeesRequest,
 *   !proto.qowner.erp.api.v1.services.ListEmployeesResponse>}
 */
const methodDescriptor_Employees_ListEmployees = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Employees/ListEmployees',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ListEmployeesRequest,
  proto.qowner.erp.api.v1.services.ListEmployeesResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ListEmployeesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.ListEmployeesResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ListEmployeesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.ListEmployeesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.ListEmployeesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.EmployeesClient.prototype.listEmployees =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Employees/ListEmployees',
      request,
      metadata || {},
      methodDescriptor_Employees_ListEmployees,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ListEmployeesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.ListEmployeesResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.EmployeesPromiseClient.prototype.listEmployees =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Employees/ListEmployees',
      request,
      metadata || {},
      methodDescriptor_Employees_ListEmployees);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.GetEmployeeRequest,
 *   !proto.qowner.erp.api.v1.model.Employee>}
 */
const methodDescriptor_Employees_GetEmployee = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Employees/GetEmployee',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.GetEmployeeRequest,
  projects_erp_service_api_v1_model_employee_pb.Employee,
  /**
   * @param {!proto.qowner.erp.api.v1.services.GetEmployeeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_employee_pb.Employee.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.GetEmployeeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.Employee)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.Employee>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.EmployeesClient.prototype.getEmployee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Employees/GetEmployee',
      request,
      metadata || {},
      methodDescriptor_Employees_GetEmployee,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.GetEmployeeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.Employee>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.EmployeesPromiseClient.prototype.getEmployee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Employees/GetEmployee',
      request,
      metadata || {},
      methodDescriptor_Employees_GetEmployee);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ApplyEmployeeRequest,
 *   !proto.qowner.erp.api.v1.model.Employee>}
 */
const methodDescriptor_Employees_ApplyEmployee = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Employees/ApplyEmployee',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ApplyEmployeeRequest,
  projects_erp_service_api_v1_model_employee_pb.Employee,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ApplyEmployeeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_employee_pb.Employee.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyEmployeeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.Employee)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.Employee>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.EmployeesClient.prototype.applyEmployee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Employees/ApplyEmployee',
      request,
      metadata || {},
      methodDescriptor_Employees_ApplyEmployee,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyEmployeeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.Employee>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.EmployeesPromiseClient.prototype.applyEmployee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Employees/ApplyEmployee',
      request,
      metadata || {},
      methodDescriptor_Employees_ApplyEmployee);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.DeleteEmployeeRequest,
 *   !proto.qowner.erp.api.v1.services.DeleteEmployeeResponse>}
 */
const methodDescriptor_Employees_DeleteEmployee = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.Employees/DeleteEmployee',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.DeleteEmployeeRequest,
  proto.qowner.erp.api.v1.services.DeleteEmployeeResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.DeleteEmployeeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.DeleteEmployeeResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.DeleteEmployeeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.DeleteEmployeeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.DeleteEmployeeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.EmployeesClient.prototype.deleteEmployee =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Employees/DeleteEmployee',
      request,
      metadata || {},
      methodDescriptor_Employees_DeleteEmployee,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.DeleteEmployeeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.DeleteEmployeeResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.EmployeesPromiseClient.prototype.deleteEmployee =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.Employees/DeleteEmployee',
      request,
      metadata || {},
      methodDescriptor_Employees_DeleteEmployee);
};


module.exports = proto.qowner.erp.api.v1.services;

