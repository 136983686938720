// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import API from 'api/properties'
import DocumentsAPI from 'api/documents'
import { getFileUrl } from 'api'

export const listProperties = createAsyncThunk('appObjects/listProperties', async (params, { signal }) => {
  const response = await API.listProperties({
    ...params,
    isProject: false
  })
  if (signal.aborted)
    return
  return {
    params,
    data: response.propertiesList,
    totalRows: response.totalRows
  }
})

export const getPropertiesByProject = createAsyncThunk('appObjects/listProperties', async (projectName, { signal }) => {
  const response = await API.listProperties({
    isProject: false,
    projectName
  })
  if (signal.aborted)
    return []
  return response.propertiesList
})

export const getProperty = createAsyncThunk('appObjects/getProperty', async (name, { dispatch, getState }) => {
  const loaded = (getState().objects.data ?? []).filter(e => e.name === name)[0]
  if (loaded)
    await dispatch(appObjects.actions.setSelected(loaded))
  return await API.getProperty(name)
})

export const addProperty = createAsyncThunk('appObjects/addProperty', async (instance) => {
  const updated = await API.applyProperty({
    isProject: false,
    ...instance
  })
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const modifyProperty = createAsyncThunk('appObjects/modifyProperty', async (instance, { dispatch, getState }) => {
  const updated = await API.applyProperty({
    isProject: false,
    ...instance
  })
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appObjects.actions.setSelected(instance))
  await dispatch(listProperties(getState().objects.params))
  return instance
})

export const applyProperty = createAsyncThunk('appObjects/applyProperty', async (instance) => {
  const updated = await API.applyProperty({
    isProject: false,
    ...instance
  })
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const deleteProperty = createAsyncThunk('appObjects/deleteProperty', async (name, { dispatch, getState }) => {
  await API.deleteProperty(name)
  await dispatch(listProperties(getState().objects.params))
  return name
})

const imageLocation2type = location => location.replace('/images', '')
const imageType2Location = type => `${type}/images`
const imageLocation2folder = (location, type) => {
  const result = location.replace(`${type}/images/`, '')
  if (result === 'undefined') {
    return 'exterior'
  }
  return result
}

export const getImages = createAsyncThunk('appProjects/getImages', async (typeName) => {
  const response = await DocumentsAPI.listDocuments({ location: imageType2Location(typeName) })
  const items = {}
  for (let i = 0; i < response.length; i++) {
    const img = {
      ...response[i],
      url: getFileUrl(response[i].url)
    }
    const folder = imageLocation2folder(img.location, typeName)
    items[folder] = (items[folder] ?? []).concat([img])
  }
  const result = {}
  result[typeName] = items
  return result
})

export const getDocumentByUrl = createAsyncThunk('appProjects/getDocumentByUrl', async (url) => {
  return { url: getFileUrl(url) }
})

export const addImage = createAsyncThunk('appProjects/addImage', async (document) => {
  const updated = await DocumentsAPI.applyDocument(document)
  document = {
    ...document,
    ...updated
  }
  return document
})

export const deleteImage = createAsyncThunk('appProjects/deleteImage', async ({ name }) => {
  await DocumentsAPI.deleteDocument(name)
  return name
})

export const appObjects = createSlice({
  name: 'appObjects',
  initialState: {
    data: [],
    total: 1,
    params: {},
    images: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(listProperties.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(getProperty.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
      .addCase(getImages.fulfilled, (state, { payload }) => {
        state.images = {
          ...state.images,
          ...payload
        }
      })
      .addCase(addImage.fulfilled, (state, { payload }) => {
        const images = { ...state.images }
        const key = imageLocation2type(payload.location)
        images[key] = (images[key] ?? []).concat(payload)
        state.images = {
          ...images
        }
      })
  }
})

export default appObjects.reducer
