// source: projects/erp/service/api/v1/services/properties.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var projects_erp_service_api_v1_model_property_pb = require('./property_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_property_pb);
var projects_erp_service_api_v1_model_money_pb = require('../money_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_money_pb);
var projects_erp_service_api_v1_model_list_of_strings_pb = require('../list_of_strings_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_list_of_strings_pb);
var projects_erp_service_api_v1_model_unit_pb = require('../unit_pb.js');
goog.object.extend(proto, projects_erp_service_api_v1_model_unit_pb);
goog.exportSymbol('proto.qowner.erp.api.v1.services.ApplyPropertyRequest', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.DeletePropertyRequest', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.DeletePropertyResponse', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.GetPropertyRequest', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.ListPropertiesRequest', null, global);
goog.exportSymbol('proto.qowner.erp.api.v1.services.ListPropertiesResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qowner.erp.api.v1.services.ListPropertiesRequest.repeatedFields_, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.ListPropertiesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.ListPropertiesRequest.displayName = 'proto.qowner.erp.api.v1.services.ListPropertiesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.ListPropertiesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.qowner.erp.api.v1.services.ListPropertiesResponse.repeatedFields_, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.ListPropertiesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.ListPropertiesResponse.displayName = 'proto.qowner.erp.api.v1.services.ListPropertiesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.GetPropertyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.GetPropertyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.GetPropertyRequest.displayName = 'proto.qowner.erp.api.v1.services.GetPropertyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.ApplyPropertyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.ApplyPropertyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.ApplyPropertyRequest.displayName = 'proto.qowner.erp.api.v1.services.ApplyPropertyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.DeletePropertyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.DeletePropertyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.DeletePropertyRequest.displayName = 'proto.qowner.erp.api.v1.services.DeletePropertyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.DeletePropertyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.DeletePropertyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.DeletePropertyResponse.displayName = 'proto.qowner.erp.api.v1.services.DeletePropertyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest.displayName = 'proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse.displayName = 'proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.repeatedFields_ = [20,21,22,23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.ListPropertiesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sortColumn: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sort: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    search: (f = msg.getSearch()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    forSaleOnly: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    forRentOnly: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    typeName: (f = msg.getTypeName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    statusName: (f = msg.getStatusName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    developerName: (f = msg.getDeveloperName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    projectName: (f = msg.getProjectName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isProject: (f = msg.getIsProject()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    externalSystem: (f = msg.getExternalSystem()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    externalNumber: (f = msg.getExternalNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    externalIncluded: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    isVerified: (f = msg.getIsVerified()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isUpdated: (f = msg.getIsUpdated()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    propertyQueryName: (f = msg.getPropertyQueryName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    propertyCollectionName: (f = msg.getPropertyCollectionName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    developerNamesList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    typeNamesList: (f = jspb.Message.getRepeatedField(msg, 21)) == null ? undefined : f,
    statusNamesList: (f = jspb.Message.getRepeatedField(msg, 22)) == null ? undefined : f,
    locationNamesList: (f = jspb.Message.getRepeatedField(msg, 23)) == null ? undefined : f,
    amountRange: (f = msg.getAmountRange()) && projects_erp_service_api_v1_model_money_pb.MoneyRange.toObject(includeInstance, f),
    valuesMap: (f = msg.getValuesMap()) ? f.toObject(includeInstance, proto.qowner.erp.api.v1.model.ListOfStrings.toObject) : [],
    unitRangesMap: (f = msg.getUnitRangesMap()) ? f.toObject(includeInstance, proto.qowner.erp.api.v1.model.UnitRange.toObject) : [],
    amenitiesMap: (f = msg.getAmenitiesMap()) ? f.toObject(includeInstance, proto.qowner.erp.api.v1.model.ListOfStrings.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.ListPropertiesRequest;
  return proto.qowner.erp.api.v1.services.ListPropertiesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortColumn(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSort(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSearch(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForSaleOnly(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setForRentOnly(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTypeName(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setStatusName(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDeveloperName(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setProjectName(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsProject(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setExternalSystem(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setExternalNumber(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExternalIncluded(value);
      break;
    case 16:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsVerified(value);
      break;
    case 17:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsUpdated(value);
      break;
    case 18:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPropertyQueryName(value);
      break;
    case 19:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPropertyCollectionName(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.addDeveloperNames(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.addTypeNames(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.addStatusNames(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.addLocationNames(value);
      break;
    case 24:
      var value = new projects_erp_service_api_v1_model_money_pb.MoneyRange;
      reader.readMessage(value,projects_erp_service_api_v1_model_money_pb.MoneyRange.deserializeBinaryFromReader);
      msg.setAmountRange(value);
      break;
    case 25:
      var value = msg.getValuesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.qowner.erp.api.v1.model.ListOfStrings.deserializeBinaryFromReader, "", new proto.qowner.erp.api.v1.model.ListOfStrings());
         });
      break;
    case 26:
      var value = msg.getUnitRangesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.qowner.erp.api.v1.model.UnitRange.deserializeBinaryFromReader, "", new proto.qowner.erp.api.v1.model.UnitRange());
         });
      break;
    case 27:
      var value = msg.getAmenitiesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.qowner.erp.api.v1.model.ListOfStrings.deserializeBinaryFromReader, "", new proto.qowner.erp.api.v1.model.ListOfStrings());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.ListPropertiesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSortColumn();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSort();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSearch();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getForSaleOnly();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getForRentOnly();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTypeName();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStatusName();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDeveloperName();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getProjectName();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsProject();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getExternalSystem();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExternalNumber();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExternalIncluded();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getIsVerified();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsUpdated();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPropertyQueryName();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getPropertyCollectionName();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDeveloperNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      20,
      f
    );
  }
  f = message.getTypeNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      21,
      f
    );
  }
  f = message.getStatusNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      22,
      f
    );
  }
  f = message.getLocationNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      23,
      f
    );
  }
  f = message.getAmountRange();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      projects_erp_service_api_v1_model_money_pb.MoneyRange.serializeBinaryToWriter
    );
  }
  f = message.getValuesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(25, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.qowner.erp.api.v1.model.ListOfStrings.serializeBinaryToWriter);
  }
  f = message.getUnitRangesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(26, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.qowner.erp.api.v1.model.UnitRange.serializeBinaryToWriter);
  }
  f = message.getAmenitiesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(27, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.qowner.erp.api.v1.model.ListOfStrings.serializeBinaryToWriter);
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 per_page = 2;
 * @return {number}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string sort_column = 3;
 * @return {string}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getSortColumn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setSortColumn = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool sort = 4;
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getSort = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setSort = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue search = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getSearch = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
*/
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setSearch = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearSearch = function() {
  return this.setSearch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.hasSearch = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool for_sale_only = 6;
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getForSaleOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setForSaleOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool for_rent_only = 7;
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getForRentOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setForRentOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional google.protobuf.StringValue type_name = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getTypeName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
*/
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setTypeName = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearTypeName = function() {
  return this.setTypeName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.hasTypeName = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.StringValue status_name = 9;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getStatusName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 9));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
*/
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setStatusName = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearStatusName = function() {
  return this.setStatusName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.hasStatusName = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.StringValue developer_name = 10;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getDeveloperName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 10));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
*/
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setDeveloperName = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearDeveloperName = function() {
  return this.setDeveloperName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.hasDeveloperName = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.StringValue project_name = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getProjectName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
*/
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setProjectName = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearProjectName = function() {
  return this.setProjectName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.hasProjectName = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.BoolValue is_project = 12;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getIsProject = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 12));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
*/
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setIsProject = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearIsProject = function() {
  return this.setIsProject(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.hasIsProject = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue external_system = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getExternalSystem = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
*/
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setExternalSystem = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearExternalSystem = function() {
  return this.setExternalSystem(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.hasExternalSystem = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.protobuf.StringValue external_number = 14;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getExternalNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 14));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
*/
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setExternalNumber = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearExternalNumber = function() {
  return this.setExternalNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.hasExternalNumber = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional bool external_included = 15;
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getExternalIncluded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setExternalIncluded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional google.protobuf.BoolValue is_verified = 16;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getIsVerified = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 16));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
*/
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setIsVerified = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearIsVerified = function() {
  return this.setIsVerified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.hasIsVerified = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.BoolValue is_updated = 17;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getIsUpdated = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 17));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
*/
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setIsUpdated = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearIsUpdated = function() {
  return this.setIsUpdated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.hasIsUpdated = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.protobuf.StringValue property_query_name = 18;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getPropertyQueryName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 18));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
*/
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setPropertyQueryName = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearPropertyQueryName = function() {
  return this.setPropertyQueryName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.hasPropertyQueryName = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional google.protobuf.StringValue property_collection_name = 19;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getPropertyCollectionName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 19));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
*/
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setPropertyCollectionName = function(value) {
  return jspb.Message.setWrapperField(this, 19, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearPropertyCollectionName = function() {
  return this.setPropertyCollectionName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.hasPropertyCollectionName = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * repeated string developer_names = 20;
 * @return {!Array<string>}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getDeveloperNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setDeveloperNamesList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.addDeveloperNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearDeveloperNamesList = function() {
  return this.setDeveloperNamesList([]);
};


/**
 * repeated string type_names = 21;
 * @return {!Array<string>}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getTypeNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 21));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setTypeNamesList = function(value) {
  return jspb.Message.setField(this, 21, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.addTypeNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 21, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearTypeNamesList = function() {
  return this.setTypeNamesList([]);
};


/**
 * repeated string status_names = 22;
 * @return {!Array<string>}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getStatusNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 22));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setStatusNamesList = function(value) {
  return jspb.Message.setField(this, 22, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.addStatusNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 22, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearStatusNamesList = function() {
  return this.setStatusNamesList([]);
};


/**
 * repeated string location_names = 23;
 * @return {!Array<string>}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getLocationNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 23));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setLocationNamesList = function(value) {
  return jspb.Message.setField(this, 23, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.addLocationNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 23, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearLocationNamesList = function() {
  return this.setLocationNamesList([]);
};


/**
 * optional qowner.erp.api.v1.model.MoneyRange amount_range = 24;
 * @return {?proto.qowner.erp.api.v1.model.MoneyRange}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getAmountRange = function() {
  return /** @type{?proto.qowner.erp.api.v1.model.MoneyRange} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_money_pb.MoneyRange, 24));
};


/**
 * @param {?proto.qowner.erp.api.v1.model.MoneyRange|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
*/
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.setAmountRange = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearAmountRange = function() {
  return this.setAmountRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.hasAmountRange = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * map<string, qowner.erp.api.v1.model.ListOfStrings> values = 25;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.qowner.erp.api.v1.model.ListOfStrings>}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getValuesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.qowner.erp.api.v1.model.ListOfStrings>} */ (
      jspb.Message.getMapField(this, 25, opt_noLazyCreate,
      proto.qowner.erp.api.v1.model.ListOfStrings));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearValuesMap = function() {
  this.getValuesMap().clear();
  return this;};


/**
 * map<string, qowner.erp.api.v1.model.UnitRange> unit_ranges = 26;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.qowner.erp.api.v1.model.UnitRange>}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getUnitRangesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.qowner.erp.api.v1.model.UnitRange>} */ (
      jspb.Message.getMapField(this, 26, opt_noLazyCreate,
      proto.qowner.erp.api.v1.model.UnitRange));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearUnitRangesMap = function() {
  this.getUnitRangesMap().clear();
  return this;};


/**
 * map<string, qowner.erp.api.v1.model.ListOfStrings> amenities = 27;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.qowner.erp.api.v1.model.ListOfStrings>}
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.getAmenitiesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.qowner.erp.api.v1.model.ListOfStrings>} */ (
      jspb.Message.getMapField(this, 27, opt_noLazyCreate,
      proto.qowner.erp.api.v1.model.ListOfStrings));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesRequest} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesRequest.prototype.clearAmenitiesMap = function() {
  this.getAmenitiesMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.qowner.erp.api.v1.services.ListPropertiesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.ListPropertiesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.ListPropertiesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.ListPropertiesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ListPropertiesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    propertiesList: jspb.Message.toObjectList(msg.getPropertiesList(),
    projects_erp_service_api_v1_model_property_pb.Property.toObject, includeInstance),
    totalRows: jspb.Message.getFieldWithDefault(msg, 2, 0),
    totalPages: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesResponse}
 */
proto.qowner.erp.api.v1.services.ListPropertiesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.ListPropertiesResponse;
  return proto.qowner.erp.api.v1.services.ListPropertiesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.ListPropertiesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesResponse}
 */
proto.qowner.erp.api.v1.services.ListPropertiesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_property_pb.Property;
      reader.readMessage(value,projects_erp_service_api_v1_model_property_pb.Property.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalRows(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotalPages(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.ListPropertiesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.ListPropertiesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.ListPropertiesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ListPropertiesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      projects_erp_service_api_v1_model_property_pb.Property.serializeBinaryToWriter
    );
  }
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getTotalPages();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * repeated qowner.erp.api.v1.model.Property properties = 1;
 * @return {!Array<!proto.qowner.erp.api.v1.model.Property>}
 */
proto.qowner.erp.api.v1.services.ListPropertiesResponse.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.qowner.erp.api.v1.model.Property>} */ (
    jspb.Message.getRepeatedWrapperField(this, projects_erp_service_api_v1_model_property_pb.Property, 1));
};


/**
 * @param {!Array<!proto.qowner.erp.api.v1.model.Property>} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesResponse} returns this
*/
proto.qowner.erp.api.v1.services.ListPropertiesResponse.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.qowner.erp.api.v1.model.Property=} opt_value
 * @param {number=} opt_index
 * @return {!proto.qowner.erp.api.v1.model.Property}
 */
proto.qowner.erp.api.v1.services.ListPropertiesResponse.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.qowner.erp.api.v1.model.Property, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesResponse} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesResponse.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};


/**
 * optional int64 total_rows = 2;
 * @return {number}
 */
proto.qowner.erp.api.v1.services.ListPropertiesResponse.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesResponse} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesResponse.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 total_pages = 3;
 * @return {number}
 */
proto.qowner.erp.api.v1.services.ListPropertiesResponse.prototype.getTotalPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.qowner.erp.api.v1.services.ListPropertiesResponse} returns this
 */
proto.qowner.erp.api.v1.services.ListPropertiesResponse.prototype.setTotalPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.GetPropertyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.GetPropertyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.GetPropertyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.GetPropertyRequest}
 */
proto.qowner.erp.api.v1.services.GetPropertyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.GetPropertyRequest;
  return proto.qowner.erp.api.v1.services.GetPropertyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.GetPropertyRequest}
 */
proto.qowner.erp.api.v1.services.GetPropertyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.GetPropertyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.GetPropertyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.GetPropertyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qowner.erp.api.v1.services.GetPropertyRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.services.GetPropertyRequest} returns this
 */
proto.qowner.erp.api.v1.services.GetPropertyRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.ApplyPropertyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.ApplyPropertyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ApplyPropertyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    property: (f = msg.getProperty()) && projects_erp_service_api_v1_model_property_pb.Property.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.ApplyPropertyRequest}
 */
proto.qowner.erp.api.v1.services.ApplyPropertyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.ApplyPropertyRequest;
  return proto.qowner.erp.api.v1.services.ApplyPropertyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.ApplyPropertyRequest}
 */
proto.qowner.erp.api.v1.services.ApplyPropertyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new projects_erp_service_api_v1_model_property_pb.Property;
      reader.readMessage(value,projects_erp_service_api_v1_model_property_pb.Property.deserializeBinaryFromReader);
      msg.setProperty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.ApplyPropertyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.ApplyPropertyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.ApplyPropertyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProperty();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      projects_erp_service_api_v1_model_property_pb.Property.serializeBinaryToWriter
    );
  }
};


/**
 * optional qowner.erp.api.v1.model.Property property = 1;
 * @return {?proto.qowner.erp.api.v1.model.Property}
 */
proto.qowner.erp.api.v1.services.ApplyPropertyRequest.prototype.getProperty = function() {
  return /** @type{?proto.qowner.erp.api.v1.model.Property} */ (
    jspb.Message.getWrapperField(this, projects_erp_service_api_v1_model_property_pb.Property, 1));
};


/**
 * @param {?proto.qowner.erp.api.v1.model.Property|undefined} value
 * @return {!proto.qowner.erp.api.v1.services.ApplyPropertyRequest} returns this
*/
proto.qowner.erp.api.v1.services.ApplyPropertyRequest.prototype.setProperty = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.qowner.erp.api.v1.services.ApplyPropertyRequest} returns this
 */
proto.qowner.erp.api.v1.services.ApplyPropertyRequest.prototype.clearProperty = function() {
  return this.setProperty(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.qowner.erp.api.v1.services.ApplyPropertyRequest.prototype.hasProperty = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.DeletePropertyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.DeletePropertyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.DeletePropertyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.DeletePropertyRequest}
 */
proto.qowner.erp.api.v1.services.DeletePropertyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.DeletePropertyRequest;
  return proto.qowner.erp.api.v1.services.DeletePropertyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.DeletePropertyRequest}
 */
proto.qowner.erp.api.v1.services.DeletePropertyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.DeletePropertyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.DeletePropertyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.DeletePropertyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qowner.erp.api.v1.services.DeletePropertyRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.services.DeletePropertyRequest} returns this
 */
proto.qowner.erp.api.v1.services.DeletePropertyRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.DeletePropertyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.DeletePropertyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.DeletePropertyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.DeletePropertyResponse}
 */
proto.qowner.erp.api.v1.services.DeletePropertyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.DeletePropertyResponse;
  return proto.qowner.erp.api.v1.services.DeletePropertyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.DeletePropertyResponse}
 */
proto.qowner.erp.api.v1.services.DeletePropertyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.DeletePropertyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.DeletePropertyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.DeletePropertyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest}
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest;
  return proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest}
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest} returns this
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse}
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse;
  return proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse}
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse} returns this
 */
proto.qowner.erp.api.v1.services.GeneratePropertyDescriptionResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.qowner.erp.api.v1.services);
