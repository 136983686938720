// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/email_messages'

export const list = createAsyncThunk('appEmailTopics/list', async (params) => {
  const response = await API.listEmailTopics(params)

  return {
    params,
    data: response.emailTopicsList,
    totalRows: response.totalRows
  }
})

export const appEmailTopicsSlice = createSlice({
  name: 'appEmailTopics',
  initialState: {
    data: [],
    total: 1,
    params: {},
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
  }
})

export default appEmailTopicsSlice.reducer
