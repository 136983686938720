import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { ApiUrl, getMetadata } from '../index'
import { Customer } from './customer_pb'
import {
  GetCustomerRequest,
  ListCustomersRequest,
  ApplyCustomerRequest,
  DeleteCustomerRequest
} from './customers_pb'
import { CustomersClient } from './customers_grpc_web_pb'

let client = new CustomersClient(ApiUrl, null, null);

const toCustomerModel = (instance) => {
  let result = new Customer()
  result.setName(instance.name)
  if (instance.firstName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.firstName)
    result.setFirstName(wrapped)
  }
  if (instance.middleName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.middleName)
    result.setMiddleName(wrapped)
  }
  if (instance.lastName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.lastName)
    result.setLastName(wrapped)
  }
  if (instance.sex) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.sex)
    result.setSex(wrapped)
  }
  if (instance.mobile) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.mobile)
    result.setMobile(wrapped)
  }
  if (instance.mobile2) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.mobile2)
    result.setMobile2(wrapped)
  }
  if (instance.email) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.email)
    result.setEmail(wrapped)
  }
  if (instance.languageName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.languageName)
    result.setLanguageName(wrapped)
  }
  if (instance.countryName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.countryName)
    result.setCountryName(wrapped)
  }
	result.setStatus(instance.status)
  return result
}

const toCustomerObject = (instance) => {
  const result = {}
  Object.keys(instance).forEach(key => {
    if (key.endsWith('List') || key.endsWith('Map') || key === "amount") {
      result[key] = instance[key]
    } else if (instance[key]?.value) {
      result[key] = instance[key]?.value
    } else {
      result[key] = instance[key]
    }
  })
  return result
}

const API = {
  listCustomers: (params) => {
    const request = new ListCustomersRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    if (params?.meOnly) {
      request.setMeOnly(true)
    }
    if (params?.names) {
      request.setNamesList(params.names)
    }
    return new Promise((resolve) => {
      client.listCustomers(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          const result = response.toObject()
          resolve({
            ...result,
            customersList: result.customersList.map(toCustomerObject)
          })
        }
      });
    });
  },
  getCustomer: (name) => {
    const request = new GetCustomerRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.getCustomer(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(toCustomerObject(response.toObject()))
        }
      });
    });
  },
  applyCustomer: (instance) => {
    const applying = toCustomerModel(instance)
    const request = new ApplyCustomerRequest();
    request.setCustomer(applying)
    return new Promise((resolve) => {
      client.applyCustomer(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(toCustomerObject(response.toObject()))
        }
      })
    })
  },
  deleteCustomer: (name) => {
    const request = new DeleteCustomerRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.deleteCustomer(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
