// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from 'api/locations'

export const list = createAsyncThunk('appDistricts/list', async (_, { getState }) => {
  let districts = await API.listDistricts()
  let countries = getState().countries
  districts.forEach((e) => {
    e.countryDisplayName = countries?.data?.filter((c) => c.name === e.countryName)[0]?.displayName
  })
  return districts
})

export const get = createAsyncThunk('appDistricts/get', async name => {
  return await API.getDistrict(name)
})

export const add = createAsyncThunk('appDistricts/add', async (instance, { dispatch }) => {
  const updated = await API.applyDistrict(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(list())
  return instance
})

export const modify = createAsyncThunk('appDistricts/modify', async (instance, { dispatch }) => {
  const updated = await API.applyDistrict(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appDistrictsSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const appDistrictsSlice = createSlice({
  name: 'appDistricts',
  initialState: {
    data: []
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appDistrictsSlice.reducer
