import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { ApiUrl, getMetadata } from '../index'
import { Developer } from './developer_pb'
import {
  GetDeveloperRequest,
  ListDevelopersRequest,
  ApplyDeveloperRequest,
  DeleteDeveloperRequest
} from './developers_pb'
import { DevelopersClient } from './developers_grpc_web_pb'

let client = new DevelopersClient(ApiUrl, null, null);

const toDeveloperModel = (instance) => {
  let result = new Developer()
  result.setName(instance.name)
  result.setDisplayName(instance.displayName)
  result.setCountryName(instance.countryName)
  result.setIsActive(instance.isActive)
  result.setNote(instance.note)
  result.setUrl(instance.url)
  result.setEmail(instance.email)
  result.setDescription(instance.description)
  return result
}

const API = {
  listDevelopers: (params) => {
    const request = new ListDevelopersRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    return new Promise((resolve) => {
      client.listDevelopers(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getDeveloper: (name) => {
    const request = new GetDeveloperRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.getDeveloper(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyDeveloper: (instance) => {
    const applying = toDeveloperModel(instance)
    const request = new ApplyDeveloperRequest();
    request.setDeveloper(applying)
    return new Promise((resolve) => {
      client.applyDeveloper(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteDeveloper: (name) => {
    const request = new DeleteDeveloperRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.deleteDeveloper(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
