import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { ApiUrl, getMetadata } from '../index'
import { Employee } from './employee_pb'
import {
  GetEmployeeRequest,
  ListEmployeesRequest,
  ApplyEmployeeRequest,
  DeleteEmployeeRequest
} from './employees_pb'
import { EmployeesClient } from './employees_grpc_web_pb'

let client = new EmployeesClient(ApiUrl, null, null);

const toEmployeeModel = (instance) => {
  let result = new Employee()
  result.setUserName(instance.name)
  result.setDisplayName(instance.displayName)
  result.setIsActive(instance.isActive)
  result.setLogoUrl(instance.logoUrl)
  return result
}

const API = {
  listEmployees: (params) => {
    const request = new ListEmployeesRequest()
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    return new Promise((resolve) => {
      client.listEmployees(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getEmployee: (name) => {
    const request = new GetEmployeeRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.getEmployee(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyEmployee: (instance) => {
    const applying = toEmployeeModel(instance)
    const request = new ApplyEmployeeRequest();
    request.setEmployee(applying)
    return new Promise((resolve) => {
      client.applyEmployee(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteEmployee: (name) => {
    const request = new DeleteEmployeeRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.deleteEmployee(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
