import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { ApiUrl, getMetadata } from '../index'
import { Task } from './task_pb'
import {
  GetTaskRequest,
  ListTasksRequest,
  ApplyTaskRequest,
  DeleteTaskRequest
} from './tasks_pb'
import { TasksClient } from './tasks_grpc_web_pb'

let client = new TasksClient(ApiUrl, null, null);

const toTaskModel = (instance) => {
  let result = new Task()
  if (instance.name) {
    result.setName(instance.name)
  }
  if (instance.displayName) {
    result.setDisplayName(instance.displayName)
  }
  result.setDescription(instance.description)
  result.setTaskTypeName(instance.taskTypeName)
  if (instance.employeeName) {
    const wrapped = new wrappers.StringValue()
    wrapped.setValue(instance.employeeName)
    result.setEmployeeName(wrapped)
  }
  result.setEntityName(instance.entityName)
  result.setDone(instance.done)
  result.setIsBlocked(instance.isBlocked)
  result.setIsDoneConfirmed(instance.isDoneConfirmed)
  result.setDoneAt(instance.doneAt)
  return result
}

const API = {
  listTasks: (params) => {
    const request = new ListTasksRequest();
    if (params?.page) {
      request.setPage(params.page)
    }
    if (params?.perPage) {
      request.setPerPage(params.perPage)
    }
    if (params?.sort) {
      request.setSort(params.sort)
    }
    if (params?.sortColumn) {
      request.setSortColumn(params.sortColumn)
    }
    if (params?.search) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.search)
      request.setSearch(wrapped)
    }
    if (params?.taskTypeName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.taskTypeName)
      request.setTaskTypeName(wrapped)
    }
    if (params?.employeeName) {
      const wrapped = new wrappers.StringValue()
      wrapped.setValue(params.employeeName)
      request.setEmployeeName(wrapped)
    }
    if (params?.myOnly) {
      request.setMyOnly(true)
    }
    if (params?.isDone !== undefined) {
      const wrapped = new wrappers.BoolValue()
      wrapped.setValue(!!params.isDone)
      request.setIsDone(wrapped)
    }
    if (params?.isBlocked !== undefined) {
      const wrapped = new wrappers.BoolValue()
      wrapped.setValue(!!params.isBlocked)
      request.setIsBlocked(wrapped)
    }
    return new Promise((resolve) => {
      client.listTasks(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  getTask: (name) => {
    const request = new GetTaskRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.getTask(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  },
  applyTask: (instance) => {
    const applying = toTaskModel(instance)
    const request = new ApplyTaskRequest();
    request.setTask(applying)
    return new Promise((resolve) => {
      client.applyTask(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      })
    })
  },
  deleteTask: (name) => {
    const request = new DeleteTaskRequest()
    request.setName(name)
    return new Promise((resolve) => {
      client.deleteTask(request, getMetadata(), (err, response) => {
        if (response == null) {
          console.log(err)
        } else {
          resolve(response.toObject())
        }
      });
    });
  }
};

export default API;
