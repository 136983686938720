// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from 'api/locations'

export const list = createAsyncThunk('appSettlements/list', async (_, { getState }) => {
  let settlements = (await API.listSettlements()).settlementsList
  let districts = getState().districts
  let countries = getState().countries
  settlements.forEach((e) => {
    e.districtDisplayName = districts?.data?.filter((c) => c.name === e.districtName)[0]?.displayName
    e.countryDisplayName = countries?.data?.filter((c) => c.name === e.countryName)[0]?.displayName
  })
  return settlements
})

export const listPaging = createAsyncThunk('appSettlements/listPaging', async (params, { signal, getState }) => {
  let response = await API.listSettlements({
    ...params
  })
  if (signal.aborted)
    return
  let districts = getState().districts
  let countries = getState().countries
  response.settlementsList.forEach((e) => {
    e.districtDisplayName = districts?.data?.filter((c) => c.name === e.districtName)[0]?.displayName
    e.countryDisplayName = countries?.data?.filter((c) => c.name === e.countryName)[0]?.displayName
  })
  return {
    params,
    data: response.settlementsList,
    totalRows: response.totalRows
  }
})

export const get = createAsyncThunk('appSettlements/get', async name => {
  return await API.getSettlement(name)
})

export const add = createAsyncThunk('appSettlements/add', async (instance, { dispatch }) => {
  const updated = await API.applySettlement(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(list())
  return instance
})

export const modify = createAsyncThunk('appSettlements/modify', async (instance, { dispatch }) => {
  const updated = await API.applySettlement(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appSettlementsSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const appSettlementsSlice = createSlice({
  name: 'appSettlements',
  initialState: {
    data: [],
    dataPaging: [],
    total: 1,
    params: {},
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(listPaging.fulfilled, (state, { payload }) => {
        state.dataPaging = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appSettlementsSlice.reducer
