import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { ApiUrl, getMetadata } from '../index'
import { EmailMessage } from './email_messages_pb'
import { EmailOwner } from './email_owner_pb'
import { EmailTopic } from './email_topic_pb'
import {
    GetEmailMessageRequest,
    ListEmailMessagesRequest,
    ApplyEmailMessageRequest,
    SendEmailMessageRequest,
    DeleteEmailMessageRequest
} from './email_messages_pb'
import { ListEmailOwnersRequest } from './email_owners_pb'
import { ListEmailTopicsRequest } from './email_topics_pb'
import { EmailMessagesClient } from './email_messages_grpc_web_pb'
import { EmailOwnersClient } from './email_owners_grpc_web_pb'
import { EmailTopicsClient } from './email_topics_grpc_web_pb'

let clientEmailMessage = new EmailMessagesClient(ApiUrl, null, null);
let clientEmailOwner = new EmailOwnersClient(ApiUrl, null, null);
let clientEmailTopic = new EmailTopicsClient(ApiUrl, null, null);

const toEmailMessageModel = (instance) => {
    let result = new EmailMessage()
    if (instance.name) {
        result.setName(instance.name)
    }
    return result
}

const toEmailOwnerModel = (instance) => {
    let result = new EmailOwner()
    if (instance.name) {
        result.setName(instance.name)
    }
    return result
}

const toEmailTopicModel = (instance) => {
    let result = new EmailTopic()
    if (instance.name) {
        result.setName(instance.name)
    }
    return result
}

const API = {
    listEmailMessages: (params) => {
        const request = new ListEmailMessagesRequest();
        if (params?.page) {
            request.setPage(params.page)
        }
        if (params?.perPage) {
            request.setPerPage(params.perPage)
        }
        if (params?.sort) {
            request.setSort(params.sort)
        }
        if (params?.sortColumn) {
            request.setSortColumn(params.sortColumn)
        }
        if (params?.search) {
            const wrapped = new wrappers.StringValue()
            wrapped.setValue(params.search)
            request.setSearch(wrapped)
        }
        if (params?.ownerEmail) {
            const wrapped = new wrappers.StringValue()
            wrapped.setValue(params.ownerEmail)
            request.setOwnerEmail(wrapped)
        }
        if (params?.emailsList) {
            request.setEmailsList(params?.emailsList)
        }
        if (params?.topicName) {
            const wrapped = new wrappers.StringValue()
            wrapped.setValue(params.topicName)
            request.setTopicName(wrapped)
        }
        return new Promise((resolve) => {
            clientEmailMessage.listEmailMessages(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                } else {
                    resolve(response.toObject())
                }
            });
        });
    },
    getEmailMessage: (name) => {
        const request = new GetEmailMessageRequest()
        request.setName(name)
        return new Promise((resolve) => {
            clientEmailMessage.getEmailMessage(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                } else {
                    resolve(response.toObject())
                }
            });
        });
    },
    applyEmailMessage: (instance) => {
        const applying = toEmailMessageModel(instance)
        const request = new ApplyEmailMessageRequest();
        request.setEmailMessage(applying)
        return new Promise((resolve) => {
            clientEmailMessage.applyEmailMessage(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                } else {
                    resolve(response.toObject())
                }
            })
        })
    },
    sendEmailMessage: (instance) => {
        const applying = toEmailMessageModel(instance)
        const request = new SendEmailMessageRequest();
        request.setEmailMessage(applying)
        return new Promise((resolve) => {
            clientEmailMessage.sendEmailMessage(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                } else {
                    resolve(response.toObject())
                }
            })
        })
    },
    deleteEmailMessage: (name) => {
        const request = new DeleteEmailMessageRequest()
        request.setName(name)
        return new Promise((resolve) => {
            clientEmailMessage.deleteEmailMessage(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                } else {
                    resolve(response.toObject())
                }
            });
        });
    },
    listEmailOwners: (params) => {
        const request = new ListEmailOwnersRequest()
        if (params?.page) {
            request.setPage(params.page)
        }
        if (params?.perPage) {
            request.setPerPage(params.perPage)
        }
        if (params?.sort) {
            request.setSort(params.sort)
        }
        if (params?.sortColumn) {
            request.setSortColumn(params.sortColumn)
        }
        return new Promise((resolve) => {
            clientEmailOwner.listEmailOwners(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                } else {
                    resolve(response.toObject())
                }
            });
        })
    },
    listEmailTopics: (params) => {
        const request = new ListEmailTopicsRequest()
        if (params?.page) {
            request.setPage(params.page)
        }
        if (params?.perPage) {
            request.setPerPage(params.perPage)
        }
        if (params?.sort) {
            request.setSort(params.sort)
        }
        if (params?.sortColumn) {
            request.setSortColumn(params.sortColumn)
        }
        if (params?.search) {
            const wrapped = new wrappers.StringValue()
            wrapped.setValue(params.search)
            request.setSearch(wrapped)
        }
        if (params?.ownerEmail) {
            const wrapped = new wrappers.StringValue()
            wrapped.setValue(params.ownerEmail)
            request.setOwnerEmail(wrapped)
        }
        if ((params?.emails ?? []).length) {
            request.setEmailsList(params.emails)
        }
        return new Promise((resolve) => {
            clientEmailTopic.listEmailTopics(request, getMetadata(), (err, response) => {
                if (response == null) {
                    console.log(err)
                } else {
                    resolve(response.toObject())
                }
            });
        })
    }
};

export default API;
