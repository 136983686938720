// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import API from 'api/properties'
import ConfirmationAPI from 'api/property_confirmations'
import DocumentsAPI from 'api/documents'
import { getFileUrl } from 'api'

export const listProperties = createAsyncThunk('appProjects/listProperties', async (params, { signal }) => {
  const response = await API.listProperties({
    ...params,
    isProject: true
  })
  if (signal.aborted)
    return
  return {
    params,
    data: response.propertiesList,
    totalRows: response.totalRows
  }
})

export const getPropertiesByDeveloper = createAsyncThunk('appObjects/getPropertiesByDeveloper', async (developerName, { signal }) => {
  const response = await API.listProperties({
    isProject: true,
    developerName
  })
  if (signal.aborted)
    return []
  return response.propertiesList
})

export const getProperty = createAsyncThunk('appProjects/getProperty', async (name, { dispatch, getState }) => {
  const loaded = (getState().projects.data ?? []).filter(e => e.name === name)[0]
  if (loaded)
    await dispatch(appProjects.actions.setSelected(loaded))
  return await API.getProperty(name)
})

export const addProperty = createAsyncThunk('appProjects/addProperty', async (instance) => {
  const updated = await API.applyProperty({
    isProject: true,
    ...instance
  })
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

export const modifyProperty = createAsyncThunk('appProjects/modifyProperty', async (instance, { dispatch, getState }) => {
  const updated = await API.applyProperty({
    isProject: true,
    ...instance
  })
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appProjects.actions.setSelected(instance))
  await dispatch(listProperties(getState().projects.params))
  return instance
})

export const deleteProperty = createAsyncThunk('appProjects/deleteProperty', async (name) => {
  await API.deleteProperty(name)
  return name
})

export const generatePropertyDescription = createAsyncThunk('appProjects/generatePropertyDescription', async (name) => {
  return await API.generatePropertyDescription(name)
})

export const listPropertyConfirmations = createAsyncThunk('appProjects/listPropertyConfirmations', async (propertyName, { dispatch, getState }) => {
  return await ConfirmationAPI.listPropertyConfirmations({ propertyName })
})

export const addPropertyConfirmation = createAsyncThunk('appProjects/addPropertyConfirmation', async (instance) => {
  const updated = await ConfirmationAPI.applyPropertyConfirmation(instance)
  instance = {
    ...instance,
    ...updated
  }
  return instance
})

const imageLocation2type = location => location.replace('/images', '')
const imageType2Location = type => `${type}/images`
const imageLocation2folder = (location, type) => {
  const result = location.replace(`${type}/images/`, '')
  if (result === 'undefined') {
    return 'exterior'
  }
  return result
}
const brochureLocation2type = location => location.replace('/brochures', '')
const brochureType2Location = type => `${type}/brochures`
const fileIsBrochure = location => location.endsWith('/brochures')

export const getImages = createAsyncThunk('appProjects/getImages', async (typeName) => {
  const response = await DocumentsAPI.listDocuments({ location: imageType2Location(typeName) })
  const items = {}
  for (let i = 0; i < response.length; i++) {
    const img = {
      ...response[i],
      url: getFileUrl(response[i].url)
    }
    const folder = imageLocation2folder(img.location, typeName)
    items[folder] = (items[folder] ?? []).concat([img])
  }
  const result = {}
  result[typeName] = items
  return result
})

export const getDocumentByUrl = createAsyncThunk('appProjects/getDocumentByUrl', async (url) => {
  return { url: getFileUrl(url) }
})

export const addImage = createAsyncThunk('appProjects/addImage', async (document) => {
  const updated = await DocumentsAPI.applyDocument(document)
  document = {
    ...document,
    ...updated
  }
  return document
})

export const deleteImage = createAsyncThunk('appProjects/deleteImage', async ({ name }) => {
  await DocumentsAPI.deleteDocument(name)
  return name
})

export const getBrochures = createAsyncThunk('appProjects/getBrochures', async (typeName) => {
  const response = await DocumentsAPI.listDocuments({ location: brochureType2Location(typeName) })
  const items = []
  for (let i = 0; i < response.length; i++) {
    const doc = {
      ...response[i],
      url: getFileUrl(response[i].url)
    }
    items.push(doc)
  }
  const result = {}
  result[typeName] = items
  return result
})

export const getBrochureByName = createAsyncThunk('appProjects/getBrochureByName', async (name) => {
  return await DocumentsAPI.getDocument(name)
})

export const addBrochure = createAsyncThunk('appProjects/addBrochure', async (document) => {
  const updated = await DocumentsAPI.applyDocument(document)
  document = {
    ...document,
    ...updated
  }
  return document
})

export const deleteBrochure = createAsyncThunk('appProjects/deleteBrochure', async ({ name, location }, { dispatch }) => {
  await DocumentsAPI.deleteDocument(name)
  await dispatch(getBrochures(brochureLocation2type(location)))
  return name
})

export const appProjects = createSlice({
  name: 'appProjects',
  initialState: {
    data: [],
    total: 1,
    params: {},
    images: {},
    brochures: {}
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(listProperties.fulfilled, (state, { payload }) => {
        state.data = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(getProperty.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
      .addCase(getImages.fulfilled, (state, { payload }) => {
        state.images = {
          ...state.images,
          ...payload
        }
      })
      .addCase(addImage.fulfilled, (state, { payload }) => {
        const images = { ...state.images }
        const key = imageLocation2type(payload.location)
        const keyParts = key.split('/')
        const propertyKey = keyParts.slice(0, 2).join('/')
        const typeImage = keyParts[2]
        images[propertyKey] = (images[propertyKey] ?? {})
        images[propertyKey][typeImage] = (images[propertyKey][typeImage] ?? []).concat(payload)
        state.images = {
          ...images
        }
      })
      .addCase(deleteImage.fulfilled, (state, { payload }) => {
        const images = { ...state.images }
        for (const propertyName in images) {
          for (const imageType in images[propertyName]) {
            images[propertyName][imageType] = images[propertyName][imageType].filter(e => e.name !== payload)
          }
        }
        state.images = {
          ...images
        }
      })
      .addCase(getBrochures.fulfilled, (state, { payload }) => {
        state.brochures = {
          ...state.brochures,
          ...payload
        }
      })
      .addCase(addBrochure.fulfilled, (state, { payload }) => {
        const brochures = { ...state.brochures }
        const key = brochureLocation2type(payload.location)
        brochures[key] = brochures[key].concat(payload)
        state.brochures = {
          ...brochures
        }
      })
  }
})

export default appProjects.reducer
