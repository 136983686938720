/**
 * @fileoverview gRPC-Web generated client stub for qowner.erp.api.v1.services
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.20.3
// source: projects/erp/service/api/v1/services/property_types.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

var projects_erp_service_api_v1_model_property_type_pb = require('./property_type_pb.js')
const proto = {};
proto.qowner = {};
proto.qowner.erp = {};
proto.qowner.erp.api = {};
proto.qowner.erp.api.v1 = {};
proto.qowner.erp.api.v1.services = require('./property_types_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.PropertyTypesClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.qowner.erp.api.v1.services.PropertyTypesPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ListPropertyTypesRequest,
 *   !proto.qowner.erp.api.v1.services.ListPropertyTypesResponse>}
 */
const methodDescriptor_PropertyTypes_ListPropertyTypes = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyTypes/ListPropertyTypes',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ListPropertyTypesRequest,
  proto.qowner.erp.api.v1.services.ListPropertyTypesResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ListPropertyTypesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.ListPropertyTypesResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ListPropertyTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.ListPropertyTypesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.ListPropertyTypesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyTypesClient.prototype.listPropertyTypes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyTypes/ListPropertyTypes',
      request,
      metadata || {},
      methodDescriptor_PropertyTypes_ListPropertyTypes,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ListPropertyTypesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.ListPropertyTypesResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyTypesPromiseClient.prototype.listPropertyTypes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyTypes/ListPropertyTypes',
      request,
      metadata || {},
      methodDescriptor_PropertyTypes_ListPropertyTypes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.GetPropertyTypeRequest,
 *   !proto.qowner.erp.api.v1.model.PropertyType>}
 */
const methodDescriptor_PropertyTypes_GetPropertyType = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyTypes/GetPropertyType',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.GetPropertyTypeRequest,
  projects_erp_service_api_v1_model_property_type_pb.PropertyType,
  /**
   * @param {!proto.qowner.erp.api.v1.services.GetPropertyTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_property_type_pb.PropertyType.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.PropertyType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.PropertyType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyTypesClient.prototype.getPropertyType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyTypes/GetPropertyType',
      request,
      metadata || {},
      methodDescriptor_PropertyTypes_GetPropertyType,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.GetPropertyTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.PropertyType>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyTypesPromiseClient.prototype.getPropertyType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyTypes/GetPropertyType',
      request,
      metadata || {},
      methodDescriptor_PropertyTypes_GetPropertyType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.ApplyPropertyTypeRequest,
 *   !proto.qowner.erp.api.v1.model.PropertyType>}
 */
const methodDescriptor_PropertyTypes_ApplyPropertyType = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyTypes/ApplyPropertyType',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.ApplyPropertyTypeRequest,
  projects_erp_service_api_v1_model_property_type_pb.PropertyType,
  /**
   * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  projects_erp_service_api_v1_model_property_type_pb.PropertyType.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.model.PropertyType)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.model.PropertyType>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyTypesClient.prototype.applyPropertyType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyTypes/ApplyPropertyType',
      request,
      metadata || {},
      methodDescriptor_PropertyTypes_ApplyPropertyType,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.ApplyPropertyTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.model.PropertyType>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyTypesPromiseClient.prototype.applyPropertyType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyTypes/ApplyPropertyType',
      request,
      metadata || {},
      methodDescriptor_PropertyTypes_ApplyPropertyType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.qowner.erp.api.v1.services.DeletePropertyTypeRequest,
 *   !proto.qowner.erp.api.v1.services.DeletePropertyTypeResponse>}
 */
const methodDescriptor_PropertyTypes_DeletePropertyType = new grpc.web.MethodDescriptor(
  '/qowner.erp.api.v1.services.PropertyTypes/DeletePropertyType',
  grpc.web.MethodType.UNARY,
  proto.qowner.erp.api.v1.services.DeletePropertyTypeRequest,
  proto.qowner.erp.api.v1.services.DeletePropertyTypeResponse,
  /**
   * @param {!proto.qowner.erp.api.v1.services.DeletePropertyTypeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.qowner.erp.api.v1.services.DeletePropertyTypeResponse.deserializeBinary
);


/**
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.qowner.erp.api.v1.services.DeletePropertyTypeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.qowner.erp.api.v1.services.DeletePropertyTypeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.qowner.erp.api.v1.services.PropertyTypesClient.prototype.deletePropertyType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyTypes/DeletePropertyType',
      request,
      metadata || {},
      methodDescriptor_PropertyTypes_DeletePropertyType,
      callback);
};


/**
 * @param {!proto.qowner.erp.api.v1.services.DeletePropertyTypeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.qowner.erp.api.v1.services.DeletePropertyTypeResponse>}
 *     Promise that resolves to the response
 */
proto.qowner.erp.api.v1.services.PropertyTypesPromiseClient.prototype.deletePropertyType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/qowner.erp.api.v1.services.PropertyTypes/DeletePropertyType',
      request,
      metadata || {},
      methodDescriptor_PropertyTypes_DeletePropertyType);
};


module.exports = proto.qowner.erp.api.v1.services;

