// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from 'api/locations'

export const list = createAsyncThunk('appQuarters/list', async (_, { getState }) => {
  let quarters = (await API.listQuarters()).quartersList
  let districts = getState().districts
  let countries = getState().countries
  let settlements = getState().settlements
  quarters.forEach((e) => {
    e.districtDisplayName = districts?.data?.filter((c) => c.name === e.districtName)[0]?.displayName
    e.countryDisplayName = countries?.data?.filter((c) => c.name === e.countryName)[0]?.displayName
    e.settlementDisplayName = settlements?.data?.filter((c) => c.name === e.settlementName)[0]?.displayName
  })
  return quarters
})

export const listPaging = createAsyncThunk('appQuarters/listPaging', async (params, { signal, getState }) => {
  let response = await API.listQuarters({
    ...params
  })
  if (signal.aborted)
    return
  let districts = getState().districts
  let countries = getState().countries
  let settlements = getState().settlements
  response.quartersList.forEach((e) => {
    e.districtDisplayName = districts?.data?.filter((c) => c.name === e.districtName)[0]?.displayName
    e.countryDisplayName = countries?.data?.filter((c) => c.name === e.countryName)[0]?.displayName
    e.settlementDisplayName = settlements?.data?.filter((c) => c.name === e.settlementName)[0]?.displayName
  })
  return {
    params,
    data: response.quartersList,
    totalRows: response.totalRows
  }
})

export const get = createAsyncThunk('appQuarters/get', async name => {
  return await API.getQuarter(name)
})

export const add = createAsyncThunk('appQuarters/add', async (instance, { dispatch }) => {
  const updated = await API.applyQuarter(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(list())
  return instance
})

export const modify = createAsyncThunk('appQuarters/modify', async (instance, { dispatch }) => {
  const updated = await API.applyQuarter(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appQuartersSlice.actions.setSelected(instance))
  await dispatch(list())
})

export const appQuartersSlice = createSlice({
  name: 'appQuarters',
  initialState: {
    data: [],
    dataPaging: [],
    total: 1,
    params: {},
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(listPaging.fulfilled, (state, { payload }) => {
        state.dataPaging = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
  }
})

export default appQuartersSlice.reducer
