// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import API from 'api/developers/index'
import DocumentsAPI from 'api/documents'
import { getFileUrl } from 'api'

export const list = createAsyncThunk('appDevelopers/list', async (_) => {
  return (await API.listDevelopers()).developersList
})

export const listPaging = createAsyncThunk('appDevelopers/listPaging', async (params, { signal}) => {
  let response = await API.listDevelopers({
    ...params
  })
  if (signal.aborted)
    return
  return {
    params,
    data: response.developersList,
    totalRows: response.totalRows
  }
})

export const get = createAsyncThunk('appDevelopers/get', async name => {
  return await API.getDeveloper(name)
})

export const add = createAsyncThunk('appDevelopers/add', async (instance, { dispatch }) => {
  const updated = await API.applyDeveloper(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(list())
  return instance
})

export const modify = createAsyncThunk('appDevelopers/modify', async (instance, { dispatch }) => {
  const updated = await API.applyDeveloper(instance)
  instance = {
    ...instance,
    ...updated
  }
  await dispatch(appDevelopersSlice.actions.setSelected(instance))
  await dispatch(list())
  return instance
})

export const del = createAsyncThunk('appDevelopers/del', async (name, { dispatch }) => {
  await API.deleteDeveloper(name)
  await dispatch(list())
  return name
})

const logoLocation2type = location => location.replace('/logos', '')
const logoType2Location = type => `${type}/logos`
const documentLocation2type = location => location.replace('/documents', '')
const documentType2Location = type => `${type}/documents`
const fileIsLogo = location => location.endsWith('/logos')
const fileIsDocument = location => location.endsWith('/documents')

export const getAllFiles = createAsyncThunk('appDevelopersSlice/getAllFiles', async () => {
  const response = await DocumentsAPI.listDocuments({ location: "developers" })
  const result = { logos: {}, documents: {} }
  for (let i = 0; i < response.length; i++) {
    const item = {
      ...response[i],
      url: getFileUrl(response[i].url)
    }
    
    if (fileIsLogo(item.location)) {
      const key = logoLocation2type(item.location)
      result.logos[key] = (result.logos[key] ?? []).concat([item])
    } else if (fileIsDocument(item.location)) {
      const key = documentLocation2type(item.location)
      result.documents[key] = (result.documents[key] ?? []).concat([item])
    }
  }
  return result
})

export const getLogos = createAsyncThunk('appDevelopersSlice/getLogos', async (typeName) => {
  const response = await DocumentsAPI.listDocuments({ location: logoType2Location(typeName) })
  const items = []
  for (let i = 0; i < response.length; i++) {
    const logo = {
      ...response[i],
      url: getFileUrl(response[i].url)
    }
    items.push(logo)
  }
  const result = {}
  result[typeName] = items
  return result
})

export const addLogo = createAsyncThunk('appDevelopersSlice/addLogo', async (document) => {
  const updated = await DocumentsAPI.applyDocument(document)
  document = {
    ...document,
    ...updated
  }
  return document
})

export const deleteLogo = createAsyncThunk('appDevelopersSlice/deleteLogo', async ({ name, location }, { dispatch }) => {
  await DocumentsAPI.deleteDocument(name)
  await dispatch(getLogos(logoLocation2type(location)))
  return name
})

export const getDocuments = createAsyncThunk('appDevelopersSlice/getDocuments', async (typeName) => {
  const response = await DocumentsAPI.listDocuments({ location: documentType2Location(typeName) })
  const items = []
  for (let i = 0; i < response.length; i++) {
    const doc = {
      ...response[i],
      url: getFileUrl(response[i].url)
    }
    items.push(doc)
  }
  const result = {}
  result[typeName] = items
  return result
})

export const getDocumentByName = createAsyncThunk('appProjects/getDocumentByName', async (name) => {
  return await DocumentsAPI.getDocument(name)
})

export const addDocument = createAsyncThunk('appDevelopersSlice/addDocument', async (document) => {
  const updated = await DocumentsAPI.applyDocument(document)
  document = {
    ...document,
    ...updated
  }
  return document
})

export const deleteDocument = createAsyncThunk('appDevelopersSlice/deleteDocument', async ({ name, location }, { dispatch }) => {
  await DocumentsAPI.deleteDocument(name)
  await dispatch(getDocuments(documentLocation2type(location)))
  return name
})

export const appDevelopersSlice = createSlice({
  name: 'appDevelopers',
  initialState: {
    data: [],
    selected: null,
    logos: {},
    documents: {},
    dataPaging: [],
    total: 1,
    params: {},
  },
  reducers: {
    setSelected: (state, { payload }) => {
      state.selected = payload
    }
  }, extraReducers: builder => {
    builder
      .addCase(list.fulfilled, (state, { payload }) => {
        state.data = payload
      })
      .addCase(listPaging.fulfilled, (state, { payload }) => {
        state.dataPaging = payload.data
        state.params = payload.params
        state.total = payload.totalRows
      })
      .addCase(get.fulfilled, (state, { payload }) => {
        state.selected = payload
      })
      .addCase(getAllFiles.fulfilled, (state, { payload }) => {
        state.logos = payload.logos
        state.documents = payload.documents
      })
      .addCase(getLogos.fulfilled, (state, { payload }) => {
        state.logos = {
          ...state.logos,
          ...payload
        }
      })
      .addCase(addLogo.fulfilled, (state, { payload }) => {
        const logos = { ...state.logos }
        const key = logoLocation2type(payload.location)
        logos[key] = (logos[key] ?? []).concat(payload)
        state.logos = {
          ...logos
        }
      })
      .addCase(getDocuments.fulfilled, (state, { payload }) => {
        state.documents = {
          ...state.documents,
          ...payload
        }
      })
      .addCase(addDocument.fulfilled, (state, { payload }) => {
        const documents = { ...state.documents }
        const key = documentLocation2type(payload.location)
        documents[key] = documents[key].concat(payload)
        state.documents = {
          ...documents
        }
      })
  }
})

export default appDevelopersSlice.reducer
